import React, { useEffect } from 'react'

const AnimatedCursor = () => {
    const onMouseMove = (e) => {
        var cursor = document.querySelector(' .cursor')
        var cursor2 = document.querySelector(' .cursor2')
        cursor.style.cssText = cursor2.style.cssText = 'left: ' + e.clientX + 'px;  top: ' + e.clientY + 'px;'
    }

    useEffect(() => {
        window.addEventListener('mousemove', onMouseMove)
        return () => {
            window.removeEventListener('mousemove', onMouseMove)
        }
    }, [])

    return (
        <>
            <div className='cursor'></div>
            <div className='cursor2'></div>
        </>
    )
}

export default AnimatedCursor
