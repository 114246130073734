import React from 'react'

import PageTitle from '../elements/PageTitle'

const RiskDisclosure = () => {
    return (
        <>
            <div className='page-content'>
                <PageTitle activeTitle='Risk Disclosure' parentTitle='Home' />
                <section className='content-bx style-2 content-inner overflow-hidden'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-lg-12'>
                                <div className='inner-content '>
                                    <div className='section-head'>
                                        <h3 className='title wow fadeInUp mb-5' data-wow-delay='0.2s'>
                                            Risk Disclosure Statement
                                        </h3>
                                        <p className='text-small' style={{ fontSize: '0.8rem' }}>
                                            Risk disclosure: Trading cryptocurrencies is highly speculative, carries a level
                                            of risk and may not be suitable for all investors. We are required to tell
                                            potential investors that Due to the volatile nature of Digital Assets prices,
                                            User can incur a substantial and even a full loss of Funds by buying and trading
                                            Digital Assets. The user must carefully consider whether trading Digital Assets
                                            is suitable for the User’s risk tolerance and financial position. User should not
                                            use Funds for the Services that User is not prepared to lose entirely. You may
                                            need to seek 3rd party financial advice before engaging in cryptocurrency
                                            trading. Coiniy.com is not intended for use by any person in any country where
                                            such use would be contrary to local law or regulation. It is the responsibility
                                            of visitors to Coiniy.com to ascertain the terms of and comply with any local law
                                            or regulation to which they or the trades they undertake are subject.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Version of: 19-June-2023
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Clarifications added on: 19-June-2023
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            This policy (the “Risk Disclosure Statement”) is part of the Coiniy User
                                            Agreement. The definitions used but not defined herein are defined in the User
                                            Agreement.{' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            This policy is drawn up in English as well as other language versions. In case of
                                            any discrepancy, the English language version prevails.{' '}
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            INTRODUCTION
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            The purpose of the Risk Disclosure Statement (“the Statement”) is to provide the
                                            Clients with appropriate guidance on the nature and risks of the specific types
                                            of financial instruments offered by Coiniy for the following products:
                                        </p>
                                        <ul>
                                            <li>Trading, Buying or Selling Cryptocurrencies such as Bitcoin </li>
                                            <li>
                                                Contracts for Difference (“CFDs”)
                                                <p className='fs-1'>
                                                    User uses the services entirely at its own risk. By accepting the User
                                                    Agreement, the User also accepts and consents to the risks as included in
                                                    this Risk Disclosure Statement. This Risk Disclosure Statement includes
                                                    some of the most principal risks of using the Services, but it cannot and
                                                    does not include all risks involved in using the Services.{' '}
                                                </p>
                                            </li>
                                        </ul>
                                        <p
                                            className='font-text text-dark font-w500 wow fadeInUp text-center'
                                            data-wow-delay='0.4s'>
                                            IT IS VERY IMPORTANT THAT YOU READ AND UNDERSTAND THE FOLLOWING RISKS OF
                                            CRYPTOCURRENCY TRADING IN YOUR COINIY ACCOUNT
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Investing involves risk. This is especially the case when investing in a novel
                                            asset class such as cryptocurrencies, which are offered to{' '}
                                            <a href='https://coiniy.com/' target='_blank'>
                                                Coiniy.com
                                            </a>{' '}
                                            customers, a crypto trading services business. Investing in cryptocurrencies can
                                            result in the loss of your entire investment, and such loss can happen quickly.{' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            The{' '}
                                            <a href='https://coiniy.com/' target='_blank'>
                                                Coiniy.com
                                            </a>{' '}
                                            platform contains many references about cryptocurrency investment risks, but this
                                            document was created to emphasize these risks to investors even more. Please
                                            review the following risks that are relevant to cryptocurrency trading:
                                        </p>
                                        <ul>
                                            <li>
                                                Cryptocurrency is not legal tender, is not backed by the government, and
                                                cryptocurrencies are not subject to Federal Deposit Insurance Corporation or
                                                Securities Investor Protection Corporation protections;
                                            </li>
                                            <li>
                                                The volatility and unpredictability of the price of cryptocurrency relative
                                                to fiat currency may result in significant loss over a short period of time;{' '}
                                            </li>
                                            <li>
                                                Legislative and regulatory changes or actions at the state, federal, or
                                                international level may adversely affect the use, transfer, exchange, and
                                                value of cryptocurrency;{' '}
                                            </li>
                                            <li>
                                                Transactions in cryptocurrency may be irreversible, and, accordingly, losses
                                                due to fraudulent or accidental transactions may not be recoverable;
                                            </li>
                                            <li>
                                                Some cryptocurrency transactions shall be deemed to be made when recorded on
                                                a public ledger, which is not necessarily the date or time that the customer
                                                initiates the transaction;
                                            </li>
                                            <li>
                                                The value of cryptocurrency may be derived from the continued willingness of
                                                market participants to exchange fiat currency for cryptocurrency, which may
                                                result in the potential for permanent and total loss of value of a particular
                                                cryptocurrency should the market for that cryptocurrency disappear;{' '}
                                            </li>
                                            <li>
                                                There is no assurance that a person or entity who accepts a cryptocurrency as
                                                payment today will continue to do so in the future;{' '}
                                            </li>
                                            <li>
                                                The nature of cryptocurrency may lead to an increased risk of fraud or cyber
                                                attack;{' '}
                                            </li>
                                            <li>
                                                The nature of cryptocurrency means that any technological difficulties
                                                experienced by{' '}
                                                <a href='https://coiniy.com/' target='_blank'>
                                                    Coiniy.com
                                                </a>{' '}
                                                may prevent the access or use of a customer’s cryptocurrency;{' '}
                                            </li>
                                            <li>
                                                <a href='https://coiniy.com/' target='_blank'>
                                                    Coiniy.com
                                                </a>{' '}
                                                relies on certain services provided by its global affiliates, including
                                                trading and custody support, when providing customers access to
                                                cryptocurrency trading;{' '}
                                            </li>
                                            <li>
                                                There are trading and other fees associated with the trading and transfer of
                                                cryptocurrency. This includes a 2,6% fee for each purchase and sale of
                                                cryptocurrency.{' '}
                                            </li>
                                            <li>
                                                Any bond or trust account maintained by{' '}
                                                <a href='https://coiniy.com/' target='_blank'>
                                                    Coiniy.com
                                                </a>{' '}
                                                for the benefit of its customers may not be sufficient to cover all losses
                                                incurred by customers. Also, as noted above, no FDIC insurance covers
                                                cryptocurrency losses.{' '}
                                            </li>
                                            <li>
                                                Coiniy offers its customers a personal cryptocurrency wallet through{' '}
                                                <a href='https://coiniy.com/' target='_blank'>
                                                    Coiniy.com
                                                </a>{' '}
                                                where a customer may send or receive the cryptocurrencies supported by the
                                                wallet. The use of that wallet involves unique risks, such as the possibility
                                                of losing assets if you send cryptocurrency to the incorrect wallet.{' '}
                                            </li>
                                        </ul>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            In addition to reviewing the above, customers should do additional research
                                            before investing in a specific cryptocurrency.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            CFDs are complex instruments and come with a high risk of losing money rapidly
                                            due to leverage. 71.36% of retail investor accounts lose money when trading CFDs
                                            with this provider. You should consider whether you understand how CFDs work and
                                            whether you can afford to take the high risk of losing your money.{' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            This document provides you with key information about this investment product. It
                                            is NOT marketing material. The information is required by law to help you
                                            understand the nature, risks, costs, potential gains and losses of this product
                                            and to help you compare it with other products.{' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s' style={{ marginTop: '2rem' }}>
                                            Product Type: Contract for difference (“CFD”){' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Execution Type: Self-Directed Trading{' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Asset Class: Crypto-assets such as Bitcoin.{' '}
                                        </p>
                                        <p
                                            className='wow fadeInUp fs-1'
                                            data-wow-delay='0.6s'
                                            style={{ marginBottom: '2rem' }}>
                                            Direction: Long (buy)/Short (sell){' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            CFDs on virtual currencies are derivative products whose price depends on the
                                            movement of the pricing data and market on the Cryptocurrencies which are
                                            originated from the digital decentralized exchanges the Cryptocurrencies are
                                            traded on. Given that such exchanges are not regulated, the market data and price
                                            feed information provided by such exchanges may be subject to the internal rules
                                            and practices which may significantly differ from the rules and practices
                                            observed by the regulated exchanges. Therefore, the pricing formation rules of
                                            the Cryptocurrency exchanges are not subject to any regulatory supervision and
                                            may be changed at the relevant digital exchange’s discretion at any time.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Digital exchanges may introduce trading suspensions or take other actions that
                                            may result in suspension or cessation of trading on such exchanges or the price
                                            and market data feed becoming unavailable. The above factors could result in
                                            material adverse effect on the investor’s open positions, including the loss of
                                            all of the investor’s invested amounts.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Where a temporary or permanent disruption to or cessation of trading occurs on
                                            any digital exchange from which price feeds for the relevant Cryptocurrency are
                                            derived, investors you may be unable to close or liquidate their trades or
                                            withdraw any funds related to their trade until the trading on the relevant
                                            digital exchange resumes (if at all).{' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Where trading resumes again at either the relevant initial digital exchange or on
                                            any successor exchange thereof, there may be significant price differential
                                            (“price gaping”) which may impact the value of the investor’s positions. Where
                                            trading does not resume, the investor’s entire investment will potentially be
                                            lost altogether.{' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            When trading in CFDs where the underlying asset is a Cryptocurrency, investors
                                            need to take in consideration that Cryptocurrencies are traded on non-regulated
                                            decentralized digital exchanges. Accordingly, price formation and price movements
                                            of the Cryptocurrencies depend solely on the internal rules of the particular
                                            digital exchange, which may be subject to change at any point in time and without
                                            notice which often leads to a very high intra-day volatility in the prices, which
                                            may be significantly higher compared to the Financial Instruments other than
                                            Virtual Currencies.{' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            By trading CFDs in Cryptocurrencies investors accept a significantly higher risk
                                            of loss of your invested amounts which may occur within a very short time frame
                                            as a result of sudden adverse price movements of the Cryptocurrencies.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default RiskDisclosure
