import React from 'react'

import PageTitle from '../elements/PageTitle'

const PrivacyPolicy = () => {
    return (
        <>
            <div className='page-content'>
                <PageTitle activeTitle='Privacy Policy' parentTitle='Home' />
                <section className='content-bx style-2 content-inner overflow-hidden'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-lg-12'>
                                <div className='inner-content '>
                                    <div className='section-head'>
                                        <h3 className='title wow fadeInUp mb-5' data-wow-delay='0.2s'>
                                            Privacy Policy
                                        </h3>
                                        <p className='fs-1'>
                                            The privacy policy explains how Coiniy protects and uses your data.
                                        </p>
                                        <p className='fs-1'>Privacy Notice Coiniy</p>
                                        <p className='fs-1'>Version of: 19-June-2023</p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            1. Introduction
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Coiniy operates an online central limit order book (CLOB) trading platform, where
                                            digital assets can be exchanged for other digital assets or fiat (the Platform).
                                            You can access the Platform via our Website:{' '}
                                            <a href='https://coiniy.com/' target='_blank'>
                                                https://coiniy.com/
                                            </a>{' '}
                                            and the Coiniy app. The Platform and Website are collectively referred to as our
                                            Services.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            This is our Privacy Notice. In this document we explain what kind of personal
                                            data we collect via our Services. Personal data means all information by which a
                                            person can be directly or indirectly identified and is a term under the General
                                            Data Protection Regulation (GDPR). We also explain what role we have in the
                                            processing of personal data, how long we retain personal data and what rights you
                                            have as a data subject.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Please read this Privacy Notice carefully. Please note that Privacy Notice
                                            utilizes certain terms that are defined in the User Agreement Coiniy (User
                                            Agreement).
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            If you have questions about the processing of your personal data, you can always
                                            contact us through the contact details listed at the bottom of this Privacy
                                            Notice.{' '}
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            2. Who is responsible for the processing of your personal data?
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Coiniy is responsible for the processing of your personal data as described in
                                            this Privacy Notice and acts as ‘controller’ under the GDPR.{' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            If you have questions about the processing of your personal data, you can always
                                            contact us through the contact details listed at the bottom of this Privacy
                                            Notice.{' '}
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            3. Which personal data could be processed and from which sources?
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Coiniy may process your personal data if you:
                                        </p>
                                        <ul>
                                            <li>are a visitor/user of our Website or Services;</li>
                                            <li>
                                                are (an authorized representative or UBO of) our customer (or their related
                                                family member or close business partner);{' '}
                                            </li>
                                            <li> have a business relationship with Coiniy; and/or </li>
                                            <li>work at our service providers or other parties we deal with.</li>
                                        </ul>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            In paragraph 3.1 – 3.3 is described which data could be processed and from which
                                            source.{' '}
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            3.1 Information we collect from you automatically
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            We receive and store certain types of information automatically, such as whenever
                                            you interact with the Platform or use the Services. This information helps us
                                            address customer support issues, provide you with a streamlined and personalized
                                            experience, improve the performance of our Platform, and protect your account
                                            from fraud by detecting unauthorized access. Information collected automatically
                                            includes:{' '}
                                        </p>
                                        <ul>
                                            <li>
                                                i. Usage Data: Information on how our Services are accessed and used, such as
                                                your IP-address, when and for how long you visit the Platform, which pages
                                                you visit on the Platform, on which links you click and technical information
                                                (e.g. type of browser and operating system). Please read our Cookie Statement
                                                for more information: Coiniy cookie policy.{' '}
                                            </li>
                                        </ul>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            3.2 Information you provide to us
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            To establish an account and allow you access to our Services, we'll ask you to
                                            provide us with some important information about you. This information is either
                                            required by law (e.g. to verify your identity) or necessary to provide the
                                            requested Services (e.g. you will need to provide your bank account number if
                                            you'd like to link that to your Coiniy account). Below you will find an overview
                                            of the personal data we generally collect from our (potential) customers and
                                            individuals related to our (potential) customers.{' '}
                                        </p>
                                        <ul>
                                            <li>
                                                i. Registration data: Full name, country of residence, email address,
                                                password, affiliate code (optional) and pin code.{' '}
                                            </li>
                                            <li>
                                                ii. Identification data: Government issued identity document such as
                                                Passport, Driver's License, National Identity Card or Resident Permit (ID
                                                Document) - including your full name, date of birth, nationality, place of
                                                birth, place and date of the issuance of the document, data of expiry of the
                                                document, document type, document number, signatory and your photo. In
                                                addition, we ask you to record a video clip of yourself with audio to verify
                                                your identity. In this context we process your facial images, voice and
                                                polygon mesh data. Please note that you must cover your social security
                                                number on your ID Document before providing it to us.{' '}
                                            </li>
                                            <li>
                                                iii. Personal data included in institutional information: proof of legal
                                                formation of a company (e.g. articles of incorporation), additional legal
                                                documents – including articles of association, shareholder register,
                                                structure chart, corporate tax return and UBO statement – and personal
                                                identification (and institutional) information for all direct and indirect
                                                representatives, directors and material beneficial owners (if applicable).
                                            </li>
                                            <li>
                                                iv. Account information: Information of purpose and intended nature of the
                                                business relationship you have with us.{' '}
                                            </li>
                                            <li>
                                                v. Employment information: Profession, job title, office location,
                                                description of role, employment contract and/or annual income.{' '}
                                            </li>
                                            <li>
                                                vi. Financial information: Bank account information, transaction history,
                                                source of funds, source of wealth and/or tax identification number.{' '}
                                            </li>
                                            <li>
                                                vii. Transaction information: Information about the transactions you make on
                                                our Services, such as your name, your bank account number, the amount, the
                                                type of transaction (e.g. deposit or withdraw), the name of the recipient,
                                                the virtual currency wallet address of the recipient and/or the corresponding
                                                timestamps of each of these.{' '}
                                            </li>
                                            <li>
                                                viii. Correspondence: Survey responses or information provided to our support
                                                team and other information that you might voluntarily share with us.{' '}
                                            </li>
                                        </ul>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            As we add new features and services and applicable laws and regulations might
                                            change, you may be asked to provide additional information. In such case, we will
                                            inform you about the purposes and legal grounds for such additional processing
                                            separately and/or via an update of this Privacy Notice.
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            3.3 Information generated by us
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            We may generate information about you, for example based on information you
                                            provided to us. The categories of (personal) data may include:{' '}
                                        </p>
                                        <ul>
                                            <li>
                                                i. Risk and fraud related information: behavioral pattern information, risk
                                                profiles, risk categories, transaction profile information, transaction
                                                patterns information and internal suspicious transaction reports or
                                                intelligence, derived from information about the transactions you make on our
                                                Services - such as the type of transaction (e.g. buy transaction and/or sell
                                                transaction), the amount, the type of virtual currency, the counterparty –
                                                and other personal data we have of you, hit / no hit information on
                                                Politically Exposed Persons (PEP’s) and sanction lists – and/or notices to
                                                designated authorities including reports/notifications to FIU.{' '}
                                            </li>
                                            <li>
                                                ii. Whistle-blowing or fraud reports: We may receive your personal data if
                                                they have been necessarily included in whistle-blowing reports from Coiniy
                                                Staff Members or in fraud reports from relevant payment institutions and
                                                payments service providers, law enforcement agencies and the Financial
                                                Intelligence Units (FIU). We may draw conclusions from such personal data
                                                regarding your risk classification and include such conclusions in our
                                                systems.
                                            </li>
                                        </ul>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            3.4 Information collected/received from third parties
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            From time to time, we may obtain information about you from third party sources
                                            as required or permitted by applicable laws and regulations. These sources may
                                            include:
                                        </p>
                                        <ul>
                                            <li>
                                                i. Public Databases: We obtain information from public databases such as the
                                                relevant Chamber of Commerce, the UBO / Beneficial Owners / Transparency (or
                                                similar) register, Google searches and other (reliable and independent)
                                                sources. We may also receive such public information via third party service
                                                providers such as Comply Advantage (adverse media checks) and Lexis Nexis
                                                (information on PEP’s, sanctions and blacklists).{' '}
                                            </li>
                                            <li>
                                                ii. Blockchain Data: We may analyze public blockchain data and obtain
                                                information about your transactions from Blockchain analysis providers.{' '}
                                            </li>
                                        </ul>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            4. For what purposes are your personal data processed?
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            We may use your personal data for the following purposes:
                                        </p>
                                        <ol>
                                            <li>
                                                To comply with laws and regulations; Most of our Services are subject to laws
                                                and regulations requiring us to collect, use, and store your personal data in
                                                certain ways. For example, Coiniy must identify and verify customers using
                                                our Services in order to comply with anti-money laundering laws such as the
                                                EU context of anti-money laundering and countering the financing of terrorism
                                                (Prevention) for Directive 2015/849 and 2018/843/EU on anti-money laundering
                                                and terrorist financing. In order to verify your identity remotely, we match
                                                your photo with your identification. When you provide us with your
                                                identification, please ensure your signature and national identification
                                                number are masked. We retain your photo to check if you are a politically
                                                exposed person (PEP) and to verify your identity when you request withdrawal
                                                of digital assets.
                                            </li>
                                            <li>
                                                To enforce the terms in the User Agreement and other agreements; Coiniy
                                                handles sensitive information, such as your identification and financial
                                                data, so it is very important for us and our customers that we actively
                                                monitor, investigate, prevent, and mitigate any potentially prohibited or
                                                illegal activities, and/or prevent and detect violations of the User
                                                Agreement or agreements for other Services.{' '}
                                            </li>
                                            <li>
                                                To prevent fraud, misuse of services, or money laundering; Coiniy processes
                                                personal data that are not strictly required by law, but nevertheless are
                                                necessary to protect the legitimate interest to (i) guarantee the safety and
                                                integrity of the digital currency sector, (ii) to prevent and actively combat
                                                (attempts to commit) criminal offenses and (iii) to assist in combating fraud
                                                and other types of abuse. We also process personal data of existing customers
                                                to determine potential risk factors for new customers.
                                            </li>
                                            <li>
                                                To provide the Services; We process your personal data to provide the
                                                Services to you. For example, when you want to store funds on our platform,
                                                we require certain information such as your identification, contact
                                                information, and payment information. We cannot provide you with Services
                                                without such information.{' '}
                                            </li>
                                            <li>
                                                To provide service communications; We send administrative or account-related
                                                information to you to keep you updated about our Services, inform you of
                                                relevant security issues or updates, or provide other transaction-related
                                                information. Without such communications, you may not be aware of important
                                                developments relating to your account that may affect how you can use our
                                                Services. You may not opt-out of receiving critical service communications,
                                                such as emails or mobile notifications sent for legal or security purposes.
                                            </li>
                                            <li>
                                                To provide customer service; We process your personal data when you contact
                                                us to resolve any questions, disputes, collect fees, or to troubleshoot
                                                problems. Without processing your personal data for such purposes, we cannot
                                                respond to your requests and ensure your uninterrupted use of the Services.
                                            </li>
                                            <li>
                                                For research and development purposes; We process your personal data to
                                                better understand the way you use and interact with the Services. In
                                                addition, we use such information to customize, measure, and improve the
                                                Services and the content and layout of our website and applications, and to
                                                develop new services. Without such processing, we cannot ensure your
                                                continued enjoyment of our Services.{' '}
                                            </li>
                                            <li>
                                                To engage in marketing activities; Based on your communication preferences,
                                                we may send you marketing communications (e.g. emails or mobile
                                                notifications) to inform you about our products and services; to deliver
                                                targeted marketing; and to provide you with promotional offers and to wish
                                                you a happy birthday. Our marketing activities will be conducted based on
                                                your customer profile, including your account and trading data.{' '}
                                            </li>
                                            <li>
                                                To set price alerts; Based on your account settings you can set a price alert
                                                to be notified when the price for a specific virtual currency reaches a set
                                                threshold. We will send this notification, depending on your preference, by
                                                email or a push notification in the app.{' '}
                                            </li>
                                            <li>
                                                For accountability purposes; Coiniy retains personal data insofar necessary
                                                to protect the legitimate interest to (i) maintain a proper administration
                                                and (ii) to substantiate potential legal claims. Coiniy therefore needs to
                                                keep records of registration, identification, transactions, withdrawals of
                                                funds, access logs and important correspondence.{' '}
                                            </li>
                                        </ol>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            The following chart summarizes how we use the categories of personal data we
                                            collect from customers:
                                        </p>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>Personal Data Category as mentioned in paragraph 3</th>
                                                    <th>Sources of Personal Data</th>
                                                    <th>Purpose of Collecting Personal Data</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Online Identifiers</td>
                                                    <td>Information we collect from you automatically</td>
                                                    <td>Section 1, 3, 5, 6, 7</td>
                                                </tr>
                                                <tr>
                                                    <td>Usage Data</td>
                                                    <td>Information we collect from you automatically</td>
                                                    <td>Section 2, 3, 4, 5, 8* Please also see our Cookie Statement</td>
                                                </tr>
                                                <tr>
                                                    <td>Registration Data</td>
                                                    <td>Information you provide us</td>
                                                    <td>Section 4, 5, 6, 8, 9, 10</td>
                                                </tr>
                                                <tr>
                                                    <td>Identification Data</td>
                                                    <td>Information you provide us</td>
                                                    <td>Section 1, 3, 10</td>
                                                </tr>
                                                <tr>
                                                    <td>Institutional Information</td>
                                                    <td>Information you provide us</td>
                                                    <td>Section 1, 3</td>
                                                </tr>
                                                <tr>
                                                    <td>Financial Information</td>
                                                    <td>Information you provide us</td>
                                                    <td>Section 1, 3, 4, 10</td>
                                                </tr>
                                                <tr>
                                                    <td>Employment Information</td>
                                                    <td>Information you provide us</td>
                                                    <td>Section 1, 3, 4, 6</td>
                                                </tr>
                                                <tr>
                                                    <td>Transaction Information</td>
                                                    <td>Information you provide us</td>
                                                    <td>Section 1, 3, 4, 6, 7, 8, 9, 10</td>
                                                </tr>
                                                <tr>
                                                    <td>Account information</td>
                                                    <td>
                                                        Information you provide us, Information collected from third parties,
                                                        Information generated by us
                                                    </td>
                                                    <td>Section 1, 3</td>
                                                </tr>
                                                <tr>
                                                    <td>Correspondence</td>
                                                    <td>Information you provide us</td>
                                                    <td>Section 1, 3, 4, 5, 6, 10</td>
                                                </tr>
                                                <tr>
                                                    <td>Whistle-blowing or fraud reports</td>
                                                    <td>Information collected from third parties</td>
                                                    <td>Section 1, 2 ,3, 10</td>
                                                </tr>
                                                <tr>
                                                    <td>Public Databases</td>
                                                    <td>Information collected from third parties</td>
                                                    <td>Section 1, 3</td>
                                                </tr>
                                                <tr>
                                                    <td>Blockchain Data</td>
                                                    <td>Information collected from third parties</td>
                                                    <td>Section 1, 2, 3, 6, 10</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p className='text-muted fs-1' style={{ marginTop: '1rem' }}>
                                            *For this purpose, we process your first name, email, date of birth and country.
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            5. Legal Bases for Processing your Information
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Coiniy only processes your personal data for a specified purpose and based on a
                                            legal ground, such as your consent or when necessary for the execution of an
                                            agreement with you, to comply with a legal obligation or to protect a legitimate
                                            interest.
                                        </p>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>Section & Purpose of Processing</th>
                                                    <th>Legal Bases for Processing</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        1. To comply with laws and regulations and related legitimate
                                                        interests.
                                                    </td>
                                                    <td>
                                                        Based on our legal obligations or the legitimate interest to prevent
                                                        fraud, misuse of services, or money laundering.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        2. To enforce the terms in the User Agreement and other agreements.
                                                    </td>
                                                    <td>
                                                        Based on our legal obligations or the legitimate interest to prevent
                                                        fraud, misuse of services, or money laundering.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>3. To prevent fraud, misuse of services, or money laundering.</td>
                                                    <td>
                                                        Based on our legal obligations or the legitimate interest to prevent
                                                        fraud, misuse of services, or money laundering.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>4. To provide Services. </td>
                                                    <td>
                                                        Based on our contract with you or to take steps at your request prior
                                                        to entering into a contract.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>5. To provide service communications.</td>
                                                    <td>
                                                        Based on our contract with you or to take steps at your request prior
                                                        to entering into a contract.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>6. To provide customer service. </td>
                                                    <td>
                                                        Based on our contract with you or to take steps at your request prior
                                                        to entering into a contract.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>7. For research and development purposes.</td>
                                                    <td>
                                                        Based on our legitimate interest (see paragraph 3.4. section 7
                                                        above). When we process your personal data for our legitimate
                                                        interests we always ensure that we consider and balance any potential
                                                        impact on you and your rights under data protection laws.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>8. To engage in marketing activities. </td>
                                                    <td>
                                                        Based on our legitimate interest. When we perform our marketing
                                                        activities towards you based on your customer profile, we will make
                                                        sure we do so in a fair way.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>9. To set price alerts.</td>
                                                    <td>
                                                        Based on our legitimate interest. When we perform our marketing
                                                        activities towards you based on your customer profile, we will make
                                                        sure we do so in a fair way.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s' style={{ marginTop: '1rem' }}>
                                            Please note:{' '}
                                        </p>
                                        <ul>
                                            <li>
                                                i. If you provided Coiniy your consent for any processing of your personal
                                                data, you always have the right to withdraw your consent at any moment by
                                                emailing us at: <a href='mailto: privacy@coiniy.com'>privacy@coiniy.com</a>.
                                                Please note withdrawal of your consent does not affect the lawfulness of the
                                                processing of your personal data before such withdrawal.{' '}
                                            </li>
                                            <li>
                                                ii. If we send you targeted marketing messages based on legitimate interest,
                                                we always offer you the opportunity to unsubscribe. If you wish to
                                                unsubscribe, you can do so (i) on our website by changing your preferences
                                                under the ‘settings’ tab or (ii) in every marketing email we send you, by
                                                clicking ‘unsubscribe’.
                                            </li>
                                        </ul>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Special categories of personal data Part of your Identification Data qualifies as
                                            biometric data. We process such data as this is necessary for our legal
                                            identification and authentication purposes. In addition, we may process personal
                                            data related to criminal offenses and/or personal data revealing political
                                            opinions to assess your request to become our customer and to meet our legal
                                            obligations under anti-money laundering laws such as the EU context of anti-money
                                            laundering and countering the financing of terrorism (Prevention) for Directive
                                            2015/849 and 2018/843/EU on anti-money laundering and terrorist financing.{' '}
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            6. Does Coiniy make use of automated decision making including profiling?
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Coiniy uses software to verify and read out your ID Document and check whether
                                            the picture on your ID Document matches your video. In addition, Coiniy uses
                                            software to verify your bank account (by checking whether the name of the bank
                                            account and the name on your ID Document match) and your wallet address (by
                                            identifying your wallet address and provider in a video provided by you). If no
                                            issues are identified in such a process, the ID Document/bank account/wallet
                                            address is automatically approved. ID Documents of minors are automatically
                                            disapproved, as minors are not allowed to use our Services under the User
                                            Agreement. All other dis-approvals are always reviewed manually first by Coiniy’s
                                            customer onboarding team. If your ID Document/bank account/wallet address has
                                            been disapproved, you can request (another) manual review, express your point of
                                            view and/or contest such a decision by contacting Coiniy’s support team at:{' '}
                                            <a href='mailto: support@coiniy.com'>support@coiniy.com</a>.{' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Coiniy also monitors on an ongoing basis whether any new information would affect
                                            your risk profile, transaction profile and/or risk category and whether your
                                            behavior and (if applicable) investments are in line with the information Coiniy
                                            has about you and your risk classification. In this respect, Coiniy is using
                                            software which is automatically updating your risk profile, transaction profile
                                            and/or risk category based on your behavior and might result in soft stops (i.e.
                                            transaction will be executed but manually reviewed afterwards by the compliance
                                            team) and hard stops (i.e. transaction will only be executed after manual review
                                            and approval by the compliance team) of transactions. In addition, an update of
                                            your risk profile might result in our compliance team performing additional or
                                            more frequent reviews of your account details, such as your transactions and
                                            background information. If your transaction has been stopped, you can request
                                            (another) manual review, explain your transaction and/or appeal the stop, by
                                            contacting Coiniy’s compliance team at:{' '}
                                            <a href='mailto: compliance@coiniy.com'>compliance@coiniy.com</a>.{' '}
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            7. Who may receive your personal data?
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Coiniy only provides access to your personal data to Coiniy personnel that need
                                            to have access to your personal data to perform their tasks and duties. Coiniy
                                            ensures personnel are contractually or legally required to keep your personal
                                            data confidential.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Coiniy has instructed third parties to process personal data on our behalf if
                                            such is necessary for third parties to perform their tasks and duties. Such third
                                            parties qualify as ‘Processors’. Coiniy signs appropriate data processing
                                            agreements with all processors. Through these data processing agreements, the
                                            Processors provide at least the same level of security as provided by Coiniy and
                                            guarantee the confidentiality of your personal data. Coiniy nevertheless remains
                                            fully responsible for these processing operations and will therefore take all
                                            reasonable technical and organizational measures to protect your personal data
                                            against accidental or unlawful destruction, loss, alteration and unauthorized
                                            disclosure or access.{' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Coiniy has instructed the following categories of Processors to process personal
                                            data:{' '}
                                        </p>
                                        <ul>
                                            <li>
                                                i. Hosting service providers, including Digital Ocean Services which is
                                                located in Amsterdam - Netherlands and San Francisco, The US;{' '}
                                            </li>
                                            <li>
                                                ii. Customer support software providers, including Zoho which is located in
                                                the in Amsterdam, the Netherlands and another in Dublin, Ireland;{' '}
                                            </li>
                                            <li>
                                                iii. ID Document & facial biometrics verification software providers,
                                                including Onfido which is located in the United Kingdom;{' '}
                                            </li>
                                            <li>
                                                iv. Customer screening service provider, including Comply Advantage which is
                                                located in the United Kingdom;{' '}
                                            </li>
                                            <li>
                                                v. Customer engagement software providers, including Braze which is located
                                                in the United States.{' '}
                                            </li>
                                            <li>
                                                vi. Customer relationship software providers, including Pipedrive which is
                                                located in Estonia.{' '}
                                            </li>
                                            <li>
                                                vii. Automatic chat translations software providers, including Language I/O
                                                which is located in the United States;{' '}
                                            </li>
                                            <li>
                                                viii. Chatbot software providers, including Sales'IQ which is located in
                                                Amsterdam, the Netherlands and another in Dublin, Ireland;{' '}
                                            </li>
                                            <li>
                                                ix. Software providers for checking the quality of support messages,
                                                including Klaus which is located in Estonia.{' '}
                                            </li>
                                            <li>
                                                x. Software providers for analyzing customer reviews, including Lumoa which
                                                is located in Finland;{' '}
                                            </li>
                                            <li>
                                                xi. Business accounting software providers, including NetSuite which is
                                                located in the United Kingdom.{' '}
                                            </li>
                                        </ul>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Coiniy may share your personal data with third parties who process personal data
                                            for their own purposes (and do not qualify as Processors but as “Controllers”) in
                                            limited circumstances:
                                        </p>
                                        <ul>
                                            <li>
                                                i. Coiniy shares your personal data with relevant regulators or external and
                                                internal lawyers and/or specialist advisors .{' '}
                                            </li>
                                            <li>
                                                ii. Coiniy shares your personal data if and to the extent this is required to
                                                be able to fulfill applicable (European) rules and regulations, including
                                                assisting Financial Intelligence Units (FIU) or (other) competent supervisory
                                                authorities, law enforcement authorities or if necessary to assist in
                                                combating fraud and other types of abuse to the extent permitted by law.{' '}
                                            </li>
                                            <li>iii. Coiniy may share your personal data with our payment providers. </li>
                                            <li>
                                                iv. We are legally obliged to include (some of) your personal data in our
                                                financial administration, which has to be shared with the national tax
                                                authority. The tax authority will process these personal data in accordance
                                                with its own privacy policies.{' '}
                                            </li>
                                            <li>
                                                v. If Coiniy is the subject of a sale, merger, or other transaction, we may
                                                also share your personal data with the organization that is (planning) to
                                                acquire Coiniy.{' '}
                                            </li>
                                            <li>
                                                vi. We share your transaction hash ID with Chainalysis so that Chainalysis
                                                can enable us to analyze public blockchain data and obtain information about
                                                your transactions to ensure you are not engaged in illegal or prohibited
                                                activity under the User Agreement. Chainalysis is located in the United
                                                States.{' '}
                                            </li>
                                        </ul>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Your personal data may also be shared after requesting and obtaining your
                                            (explicit) consent.{' '}
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            8. Does Coiniy transfer personal data to recipients outside the European Economic
                                            Area?
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            We may transmit personal data to parties outside the European Union, if one of
                                            our Processors or Controllers is established outside the European Union (see
                                            paragraph 7 above). In this scope, Coiniy takes the following safeguards to
                                            ensure that personal data is adequately protected in accordance with EU law:
                                        </p>
                                        <ul>
                                            <li>
                                                i. When Coiniy transfers personal data to its customer engagement software
                                                provider(s), transaction monitoring software provider(s), customer support
                                                software provider(s) and other software providers located in the United
                                                States, it will do so based on Standard Contractual Clauses (based on Article
                                                46(2)(d) GDPR).{' '}
                                            </li>
                                            <li>
                                                ii. When Coiniy transfers personal data to its ID Document & facial
                                                biometrics verification software provider(s), customer screening service
                                                provider(s) and other software and service providers located in the United
                                                Kingdom, it will do so based on an adequacy decision (Article 45(3) GDPR).{' '}
                                            </li>
                                        </ul>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            You may contact us if you wish to receive more information or a copy of the
                                            safeguards we take in this scope necessary.{' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            If you want to consult any safeguards that Coiniy has in place to protect your
                                            personal data if we transfer your personal data outside the EER, please contact
                                            us via: <a href='mailto: privacy@coiniy.com'>privacy@coiniy.com</a>.{' '}
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            9. Your rights
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            If your personal data is processed, you have privacy rights and, of course,
                                            Coiniy respects these. More specifically, you have the right of access, deletion
                                            and rectification of personal data, objection to processing of personal data,
                                            restriction of processing of personal data and the right of data portability. You
                                            can exercise your right by contacting{' '}
                                            <a href='mailto: privacy@coiniy.com'>privacy@coiniy.com</a>. We may ask you to
                                            provide further information in order to determine your identity first, to ensure
                                            that no one else is trying to execute your privacy rights.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Coiniy will respond to your request as quickly as possible, although this can
                                            take up to one month. If more time is required to complete your request, Coiniy
                                            will let you know how much longer is needed and the reasons for the delay. The
                                            above rights are not always absolute, and sometimes we may have pressing
                                            interests or a legal obligation to deny your request. In such case, we will
                                            explain to you our reason for denying your request.{' '}
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            10. Complaints
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            If you believe that Coiniy has processed your personal data unlawfully or if you
                                            are not satisfied with Coiniy’s response to your request, you can send your
                                            complaint to <a href='mailto: privacy@coiniy.com'>privacy@coiniy.com</a>. Coiniy
                                            will respond to your complaint as quickly as possible. You may also directly
                                            contact our Data Protection Officer via{' '}
                                            <a href='mailto: dpo@coiniy.com'>dpo@coiniy.com</a>.{' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            You are always entitled to file a complaint with a data protection supervisory
                                            authority if you believe that we are not processing your personal data in
                                            accordance with the GDPR.{' '}
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            11. For how long will Coiniy keeps your personal data?
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Coiniy will not keep your personal data longer than the mandatory statutory
                                            retention period or, if such a mandatory statutory retention period does not
                                            apply, no longer than is strictly necessary to achieve the purposes for which
                                            your personal data were collected or processed.{' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Criteria for data retention Coiniy retains personal data processed to execute any
                                            agreement with you as long as the term of such agreement. Coiniy retains personal
                                            data processed to comply with a legal obligation, as long as such legal
                                            obligation applies to Coiniy. Coiniy retains personal data processed to protect
                                            any legitimate interest (as described in this Privacy Notice) for as long as
                                            necessary to achieve such protection. If Coiniy has asked for your (explicit)
                                            consent for any processing of your personal data, Coiniy retains your personal
                                            data until you withdraw your (explicit) consent or until your (explicit) consent
                                            can be deemed expired and you have not renewed such (explicit) consent. In
                                            addition to the legal obligations already mentioned in this Privacy Notice,
                                            Coiniy has the following legal obligations to (keep) retaining personal data:
                                        </p>
                                        <ul>
                                            <li>
                                                i. Personal data to the extent relevant to comply with EU Money Laundering
                                                and Terrorist Financing (Prevention): 5 years after the business relationship
                                                has been ended;{' '}
                                            </li>
                                        </ul>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            12. Updating the Privacy Notice
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Coiniy may update this Privacy Notice. Any update of the Privacy Notice will
                                            apply after announcing the update on our website or any other official
                                            communication channel. If the change to the Privacy Notice concerns a fundamental
                                            change to the nature of the processing (e.g. a new category of sensitive data
                                            processed) or if the change may be relevant to and impact upon you, Coiniy will
                                            inform you of changes to the Privacy Notice, explicitly and effectively, well in
                                            advance of the change actually taking effect.{' '}
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            13. Contact and questions about this Privacy Notice?
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            If you want to know more about Coiniy’s Privacy Notice or have any questions or
                                            recommendations, please send an email to{' '}
                                            <a href='mailto: privacy@coiniy.com'>privacy@coiniy.com</a> or contact Coiniy’s
                                            Data Protection Officer directly at{' '}
                                            <a href='mailto: dpo@coiniy.com'>dpo@coiniy.com</a>. Coiniy will respond to your
                                            request as quickly as possible.{' '}
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            Contact details
                                        </p>
                                        <p className='wow fadeInUp fs-1 fw-bolder' data-wow-delay='0.6s'>
                                            Coiniy Limited
                                        </p>
                                        <ul>
                                            <li>Address:</li>
                                            <li>
                                                E: <a href='mailto: privacy@coiniy.com'>privacy@coiniy.com</a>
                                            </li>
                                            <li>
                                                E: <a href='mailto: dpo@coiniy.com'>dpo@coiniy.com</a>
                                            </li>
                                            <li>
                                                Website:{' '}
                                                <a href='https://coiniy.com/' target='_blank'>
                                                    www.coiniy.com
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default PrivacyPolicy
