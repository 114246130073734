import React from 'react'

import PageTitle from '../elements/PageTitle'

const BonusPolicy = () => {
    return (
        <>
            <div className='page-content'>
                <PageTitle activeTitle='Bonus Policy' parentTitle='Home' />
                <section className='content-bx style-2 content-inner overflow-hidden'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-lg-12'>
                                <div className='inner-content '>
                                    <div className='section-head'>
                                        <h3 className='title wow fadeInUp mb-5' data-wow-delay='0.2s'>
                                            Bonus Policy
                                        </h3>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            The Company can provide its new Clients as well as the returning ones with many
                                            profitable reward features in the form of cash promotions, bonuses, Contracts and
                                            one-time trading credits. The above-mentioned promotions are limited time offers.
                                            The terms and conditions associated with any bonus rewards are subject to change.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Bonus can be added only when the customer agrees for it. In most cases is not
                                            automatically credited to the account balance once investment is made. It is
                                            required to contact one of the company's representatives for matching the
                                            deposit.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            To withdraw funds from an account with active contracts, promotions or bonuses,
                                            the Client is required to fulfill the trading volume requirements of 30 times the
                                            value of the bonus. The trading volume requirement begins upon adding the bonus
                                            to the Client’s account.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            All funds (including bonus and profits) may be withdrawn only in case when the
                                            preceding conditions have been fully met and fulfilled. This includes the
                                            original and later deposits.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            The Company is entitled to change, freeze or cancel the bonus’ or grant’s terms
                                            and conditions, from time to time.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            At any time, the Company has the right to remove the bonus or grant and the
                                            profits achieved by using the bonus or grant from the Client’s trading account,
                                            on its sole discretion and without giving any reasons. In this case, the Company
                                            bears no responsibility for Client’s losses and damages, including but not
                                            limited to the financial losses, and the Client does not have any claims against
                                            the Company.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            In case of any indication of fraud, manipulation and other cheating or illegal
                                            activities based on the provision of bonus the account, any and all profits or
                                            losses acquired will be voided.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'></p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default BonusPolicy
