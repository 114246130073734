import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

import { IMAGES } from '../constants/theme'
import HomeCurrencies from './HomeCurrencies'

const iconWrapper = [
    {
        id: '1',
        title: 'Lower fees & no hidden costs',
        image: IMAGES.Doublehand,
        text: 'At Coiniy, we believe in transparency and fairness. Enjoy the benefits of lower fees and say goodbye to hidden costs. We prioritize providing you with a cost-effective trading experience.'
    },
    {
        id: '2',
        title: 'Secure and reliable platform',
        image: IMAGES.World,
        text: 'Trust and security are our top priorities. With Coiniy, you can trade with confidence, knowing that our platform is built on robust security measures. Your assets are protected, and your transactions are conducted in a reliable environment.'
    },
    {
        id: '3',
        title: 'Wide range of cryptocurrencies',
        image: IMAGES.ArrowCoin,
        text: "Diversify your portfolio with Coiniy's extensive selection of cryptocurrencies. Whether you're interested in established coins or exploring new opportunities, our platform offers a diverse range of digital assets to suit your trading preferences."
    }
]

const Home = () => {
    const [onHover, setOnHover] = useState('2')
    const ref = useRef(null)

    return (
        <>
            <div className='page-content'>
                <div className='main-bnr'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-xl-8 col-md-7'>
                                <h1 className='wow fadeInUp main-home-header' data-wow-delay='0.2s'>
                                    All-in-one crypto platform to buy, sell, trade, hold and earn{' '}
                                    <span className='text-line'>cryptocurrencies</span> on Coiniy
                                </h1>
                                <p className='text text-primary wow fadeInUp header-subtext-wrapper' data-wow-delay='0.4s'>
                                    Trade Bitcoin and many other cryptocurrencies with low fees
                                </p>
                                <div className='contant-box style-1 wow fadeInUp' data-wow-delay='0.6s'>
                                    <Link
                                        to={'https://app.coiniy.com/login'}
                                        className='btn btn-lg  btn-shadow btn-primary m-r30'>
                                        START TRADING
                                    </Link>
                                </div>
                            </div>
                            <div className='col-xl-4 col-md-5'>
                                <div className='banner-media'>
                                    <img
                                        className='media  wow bounceInRight center'
                                        data-wow-delay='0.8s'
                                        src={IMAGES.Digital}
                                        alt=''
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <img className='shape1' src={IMAGES.BannerShape1} alt='' />
                </div>
                <div className='crypto-wrapper bg-light overflow-hidden'>
                    <div className='container'>
                        <div className='row'>
                            <HomeCurrencies />
                        </div>
                    </div>
                </div>
                <section className='content-inner coins-wrapper bg-light overflow-hidden'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-6 m-b30'>
                                <div className='section-head wow fadeInUp' data-wow-delay='0.2s'>
                                    <h2 className='title home-title-header'>
                                        On <span className='text-primary'>Coiniy</span> you can buy the most popular
                                        cryptocurrency
                                    </h2>
                                </div>
                                <h5 className='sub-title text-primary wow fadeInUp' data-wow-delay='0.4s'>
                                    Get started in a few minutes
                                </h5>
                                <ul className='list-style-1'>
                                    <li className='wow fadeInUp' data-wow-delay='0.6s'>
                                        Create and verify your account
                                    </li>
                                    <li className='wow fadeInUp' data-wow-delay='0.8s'>
                                        Transfer funds from your bank
                                    </li>
                                    <li className='wow fadeInUp' data-wow-delay='1.0s'>
                                        Buy, sell or exchange crypto easily
                                    </li>
                                </ul>
                                <a
                                    href='https://app.coiniy.com/register'
                                    className='btn btn-lg  btn-shadow btn-primary wow fadeInUp'
                                    data-wow-delay='1.2s'
                                    target='blank'>
                                    BUY CRYPTO NOW
                                </a>
                            </div>
                            <div className='col-lg-6 m-b30'>
                                <div className='coins-media-wrapper' ref={ref}>
                                    <div className='main-circle1'>
                                        <motion.div
                                            className='circle-box'
                                            initial={{ scale: 0, rotate: '0deg' }}
                                            whileInView={{ scale: 1, rotate: '360deg' }}
                                            //data-350="transform:scale(0) rotate(0deg);" data-550="transform:scale(1) rotate(360deg);"
                                        ></motion.div>
                                        <ul className='coin-list'>
                                            <motion.li
                                                className='skrollable skrollable-after'
                                                transition={{
                                                    ease: 'linear',
                                                    duration: 0.5,
                                                    x: { duration: 1 }
                                                }}
                                                initial={{ scale: 0, left: '50%', top: '50%' }}
                                                whileInView={{ scale: 1, left: '1.5%', top: '50%' }}>
                                                <img src={IMAGES.LogoIcon8} alt='cryptocurrency' />
                                            </motion.li>
                                            <motion.li
                                                transition={{
                                                    ease: 'linear',
                                                    duration: 0.5,
                                                    x: { duration: 1 }
                                                }}
                                                initial={{ scale: 0, left: '50%', top: '50%' }}
                                                whileInView={{ scale: 1, left: '16%', top: '16%' }}>
                                                <img src={IMAGES.LogoIcon2} alt='cryptocurrency' />
                                            </motion.li>
                                            <motion.li
                                                transition={{
                                                    ease: 'linear',
                                                    duration: 0.5,
                                                    x: { duration: 1 }
                                                }}
                                                initial={{ scale: 0, left: '50%', top: '50%' }}
                                                whileInView={{ scale: 1, left: '50%', top: '1.5%' }}>
                                                <img src={IMAGES.LogoIcon3} alt='cryptocurrency' />
                                            </motion.li>
                                            <motion.li
                                                transition={{
                                                    ease: 'linear',
                                                    duration: 0.5,
                                                    x: { duration: 1 }
                                                }}
                                                initial={{ scale: 0, left: '50%', top: '50%' }}
                                                whileInView={{ scale: 1, left: '84%', top: '16%' }}>
                                                <img src={IMAGES.LogoIcon4} alt='cryptocurrency' />
                                            </motion.li>
                                            <motion.li
                                                transition={{
                                                    ease: 'linear',
                                                    duration: 0.5,
                                                    x: { duration: 1 }
                                                }}
                                                initial={{ scale: 0, left: '50%', top: '50%' }}
                                                whileInView={{ scale: 1, left: '98%', top: '50%' }}>
                                                <img src={IMAGES.LogoIcon5} alt='cryptocurrency' />
                                            </motion.li>
                                            <motion.li
                                                transition={{
                                                    ease: 'linear',
                                                    duration: 0.5,
                                                    x: { duration: 1 }
                                                }}
                                                initial={{ scale: 0, left: '50%', top: '50%' }}
                                                whileInView={{ scale: 1, left: '84%', top: '84%' }}>
                                                <img src={IMAGES.LogoIcon1} alt='cryptocurrency' />
                                            </motion.li>
                                            <motion.li
                                                transition={{
                                                    ease: 'linear',
                                                    duration: 0.5,
                                                    x: { duration: 1 }
                                                }}
                                                initial={{ scale: 0, left: '50%', top: '50%' }}
                                                whileInView={{ scale: 1, left: '50%', top: '98%' }}>
                                                <img src={IMAGES.LogoIcon6} alt='cryptocurrency' />
                                            </motion.li>
                                            <motion.li
                                                transition={{
                                                    ease: 'linear',
                                                    duration: 0.5,
                                                    x: { duration: 1 }
                                                }}
                                                initial={{ scale: 0, left: '50%', top: '50%' }}
                                                whileInView={{ scale: 1, left: '16%', top: '84%' }}>
                                                <img src={IMAGES.LogoIcon7} alt='cryptocurrency' />
                                            </motion.li>
                                        </ul>
                                    </div>
                                    <div className='center-media logo-section-center'>
                                        <motion.img
                                            transition={{
                                                ease: 'linear',
                                                duration: 0.5,
                                                x: { duration: 1 }
                                            }}
                                            initial={{ scale: 0, rotate: '0deg' }}
                                            whileInView={{ scale: 1, rotate: '360deg' }}
                                            src={IMAGES.LogoIcon}
                                            alt='cryptocurrency'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='coin-wave'>
                        <motion.div
                            className='flex-coin1'
                            transition={{
                                ease: 'linear',
                                duration: 0.5,
                                x: { duration: 0.7 }
                            }}
                            initial={{ opacity: 0, y: '500px' }}
                            whileInView={{ opacity: 1, y: 0 }}>
                            <img src={IMAGES.LogoIcon2} alt='cryptocurrency' />
                        </motion.div>

                        <motion.div
                            className='flex-coin2'
                            transition={{
                                ease: 'linear',
                                duration: 0.5,
                                x: { duration: 0.7 }
                            }}
                            initial={{ opacity: 0, y: '200px' }}
                            whileInView={{ opacity: 1, y: 0 }}>
                            <img src={IMAGES.LogoIcon4} alt='cryptocurrency' />
                        </motion.div>
                        <div
                            className='flex-coin3'
                            data-400='transform:translateY(300px); opacity:0;'
                            data-800='transform:translateY(0); opacity:1;'>
                            <img src={IMAGES.LogoIcon1} alt='cryptocurrency' />
                        </div>
                        <motion.div
                            className='flex-coin4'
                            data-300='transform:translateY(350px); opacity:0;'
                            data-700='transform:translateY(0); opacity:1;'
                            transition={{
                                ease: 'linear',
                                duration: 0.5,
                                x: { duration: 0.7 }
                            }}
                            initial={{ opacity: 0, y: '350px' }}
                            whileInView={{ opacity: 1, y: 0 }}>
                            <img src={IMAGES.LogoIcon3} alt='cryptocurrency' />
                        </motion.div>
                        <motion.div
                            className='flex-coin5'
                            data-400='transform:translateY(300px); opacity:0;'
                            data-800='transform:translateY(0); opacity:1;'
                            transition={{
                                ease: 'linear',
                                duration: 0.5,
                                x: { duration: 0.7 }
                            }}
                            initial={{ opacity: 0, y: '300px' }}
                            whileInView={{ opacity: 1, y: 0 }}>
                            <img src={IMAGES.LogoIcon5} alt='cryptocurrency' />
                        </motion.div>
                    </div>
                </section>
                <section className='content-inner-2 content-bx style-1 '>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-lg-6 m-b30 '>
                                <div className='dz-media'>
                                    <img src={IMAGES.about1} alt='image' className='rounded' />
                                    <motion.div
                                        className='content-box wow bounceInLeft'
                                        data-wow-delay='0.6s'
                                        transition={{
                                            ease: 'linear',
                                            duration: 0.5,
                                            x: { duration: 0.6 }
                                        }}
                                        initial={{ x: '-300px' }}
                                        whileInView={{ x: 0 }}>
                                        <h6 className='m-a0'>
                                            Access analytical <br />
                                            market & price data
                                        </h6>
                                    </motion.div>
                                </div>
                            </div>
                            <div className='col-lg-6 m-b30'>
                                <div className='inner-content'>
                                    <div className='section-head'>
                                        <h3 className='title wow fadeInUp header-margin-1' data-wow-delay='0.6s'>
                                            Easy-to-use platform
                                        </h3>
                                        <motion.p
                                            className='font-text wow fadeInUp'
                                            data-wow-delay='0.8s'
                                            initial={{ opacity: 0, y: '100px' }}
                                            whileInView={{ opacity: 1, y: '0' }}
                                            transition={{ duration: 0.7 }}>
                                            We provide easy and affordable access for all investors to buy and sell
                                            cryptocurrency, including Bitcoin, Ethereum, Litecoin, Dogecoin nad 50+ other
                                            cryptocurrencies.
                                        </motion.p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='content-inner overflow-hidden'>
                    <div className='container'>
                        <div className='section-head text-center'>
                            <h2 className='title wow fadeInUp' data-wow-delay='0.4s'>
                                Trusted and beginner-friendly cryptocurrency platform
                            </h2>
                        </div>
                        <div className='row justify-content-center'>
                            {iconWrapper.map((data, ind) => (
                                <div className='col-lg-4 col-md-6 m-b30' key={ind}>
                                    <div
                                        className={`icon-bx-wraper style-1 box-hover text-center ${
                                            onHover === data.id ? 'active' : ''
                                        }`}
                                        data-wow-delay='0.6s'
                                        onMouseOver={() => setOnHover(data.id)}>
                                        <div className='icon-media'>
                                            <img src={data.image} alt='currency' />
                                        </div>
                                        <div className='icon-content'>
                                            <h4 className='title'>{data.title}</h4>
                                            <p>{data.text}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <section className='bg-light content-inner-3 form-wrapper1 registration-section'>
                    <div className='container'>
                        <div className='row align-items-center text-center'>
                            <div className='col-lg-12'>
                                <div className='section-head wow fadeInUp' data-wow-delay='0.2s'>
                                    <h4 className='title registration-header'>
                                        Trade, earn, and pay with crypto — Your all-in-one crypto platform. <br />
                                    </h4>
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <h3 className='title mb-4 registration-subheader'>Sign up for a Coiniy account today.</h3>
                                <a
                                    href='https://app.coiniy.com/register'
                                    className='btn btn-lg  btn-shadow btn-primary wow fadeInUp'
                                    data-wow-delay='1.2s'
                                    style={{ padding: '10px 60px' }}
                                    target='blank'>
                                    SIGN UP
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default Home
