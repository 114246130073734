import React from 'react'

const RiskBanner = () => {
    return (
        <div className='risk-banner'>
            Coiniy is a multi-asset investment platform. The value of your investments may go up or down. Your capital is at
            risk. Crypto assets are highly volatile and unregulated. No consumer protection.
        </div>
    )
}

export default RiskBanner
