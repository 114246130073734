import React from 'react'

import PageTitle from '../elements/PageTitle'

const TermsAndConditions = () => {
    return (
        <>
            <div className='page-content'>
                <PageTitle activeTitle='Terms and Conditions' parentTitle='Home' />
                <section className='content-bx style-2 content-inner overflow-hidden'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-lg-12'>
                                <div className='inner-content '>
                                    <div className='section-head'>
                                        <h3 className='title wow fadeInUp mb-5' data-wow-delay='0.2s'>
                                            User Agreement Coiniy Limited
                                        </h3>
                                        <p className='fs-1'>Clarifications added on: 19-June-2023 </p>
                                        <p className='fs-1'>Version of: 19-June-2023</p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            The Agreement is drawn up in English as well as other language versions. In case
                                            of any discrepancy, the English language version prevails.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            These Terms and Conditions (the “Terms” or this “Agreement”) govern the use of
                                            the electronic trading platform, including any website or mobile application (the
                                            “App”, together with the website, the “Site”) for accessing the platform, and any
                                            services provided through the platform (collectively, the “Platform”) provided by
                                            Alt Coiniy Limited (the “Company”, “we”, “us” or “our”). The Terms form a binding
                                            agreement between the Company and you, as an individual user (“you”, “your” or
                                            “User”) for your individual usage of the App and Platform. By registering for and
                                            downloading the App and using the Platform, you confirm your acceptance of this
                                            Agreement and our associated Privacy Policy. If you do not agree to these Terms,
                                            you must immediately uninstall the App and cease using the App and the Platform.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            Securities Disclaimer: No material or any other information which may be made
                                            available on the Site or Platform shall constitute or be construed as a
                                            recommendation, endorsement, offer, invitation or solicitation to enter into any
                                            transaction with or purchase any product, or otherwise deal with securities,
                                            crypto assets or other products. You further understand that none of the
                                            information providers, including any Third-Party Providers (as defined below) are
                                            advising you personally concerning the nature, potential, value or suitability of
                                            any particular security or crypto asset, portfolio of securities or crypto
                                            assets, transaction, investment strategy or other matter, and any information
                                            provided is not tailored to the investment needs of any specific person. You
                                            understand that an investment in any security or crypto asset is subject to
                                            various risks, and that discussions of any security or crypto asset published on
                                            the Site or Platform may not contain a list or description of relevant risk
                                            factors. Please note that markets change continuously, so any information,
                                            content, Third-Party Content (as defined below) or other material provided on or
                                            through the Site or Platform may not be complete or current, or may be superseded
                                            by more current information. You rely on such information at your own risk.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            No Professional or Investment Advice. Our Site and Platform are not intended to
                                            provide tax, legal, insurance or investment advice, and nothing on the Site or
                                            Platform should be construed as an offer to sell, a solicitation of an offer to
                                            buy, or a recommendation for any security or crypto asset by the Company. You
                                            alone are solely responsible for determining whether any investment, security or
                                            strategy, or any other product or service, is appropriate or suitable for you
                                            based on your investment objectives and personal and financial situation. You
                                            should consult an attorney or tax professional regarding your specific legal or
                                            tax situation.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            The User Agreement should be read in conjunction with all its annexes (the
                                            Annexes) and the following related documents:
                                        </p>
                                        <ul>
                                            <li>The Risk Disclosure Statement;</li>
                                            <li>The Privacy Policy;</li>
                                            <li>The Cookie Policy;</li>
                                            <li>The Trading Rules.</li>
                                        </ul>
                                        <span>(Together the “Related Agreements”)</span>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            In the User Agreement, Annexes and the Related Agreements, capitalized terms not
                                            otherwise defined in this User Agreement, Annexes and the Related Agreements will
                                            have the meaning as included in the Key Definitions below.
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            1. Risks
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            1.1 User should not use the Services unless User understands the associated
                                            risks. User should carefully assess whether the User’s financial situation and
                                            tolerance for risk is suitable for using the Services.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            1.2 Using the Services involves significant risks. There is an inherent risk that
                                            losses will occur as a result of using the services. User should not use Funds
                                            for the Services that User is not prepared to lose entirely. A completed
                                            transaction cannot be refunded, reversed or undone.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            1.3 Please read the Risk Disclosure Statement to understand the risks related to
                                            the Services.
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            2. Open, access, use and terminate the Account
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            2.1 Opening or using an Account is restricted to individuals or legal entities
                                            (represented by an authorized individual) who are resident or established in the
                                            European Economic Area, Gibraltar, Switzerland or the United Kingdom. By opening
                                            and using an Account, the user declares that it meets this requirement.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            2.2 Depending on the country of residence or the country from which User accesses
                                            the Services, User may not be permitted to use the Services. User is responsible
                                            for complying with all rules and regulations applicable in its country of
                                            residence and/or the country from which User accesses the Services. User
                                            represents and warrants that its use of the Services is permitted under
                                            applicable regulations.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            2.3 User represents to Coiniy.com that it is not a resident of incorporated in
                                            the United States of America or otherwise qualifies as "US Citizen", "permanent
                                            resident", "resident alien" or "US Person" as defined in applicable laws and
                                            regulations. User indemnifies Coiniy.com against any damages that Coiniy.com
                                            incurs if this representation is not correct.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            2.4 User must have full legal capacity to accept the User Agreement, to use the
                                            Services and to enter into a transaction involving Digital Asset. If the user is
                                            a natural person, he or she must be at least 18 years old.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            2.5 If User is a legal entity, the Account may only be used by a person
                                            authorized to act on behalf of the legal entity. It is the responsibility of such
                                            User to limit access to the Account to authorized persons only.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            2.6 User shall provide Coiniy.com with current, accurate and complete information
                                            during the registration and verification process. Additionally, User shall keep
                                            Coiniy.com updated if any of the provided information changes.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            2.7 User may create and use only one Account.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            2.8 User must be acting only on its own behalf. User is not allowed to transfer
                                            an Account to any other person or entity or to allow any other person or entity
                                            to make use of the Account of the User, regardless of whether such use is on
                                            User's behalf, unless Coiniy.com has granted its prior written permission to do
                                            so.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            2.9 Coiniy.com may refuse granting an Account or terminate an Account for any
                                            reason or without reason.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            2.10 User may terminate the User Agreement and its Account at any time, except if
                                            this is prohibited by applicable regulation.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            2.11 Terminating the User Agreement automatically results therein that the
                                            Account shall be closed and that the Services shall no longer be provided, unless
                                            this is necessary in relation to any rights and obligations prior to the date of
                                            termination of the User Agreement.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            2.12 Terminating the User Agreement and closing an Account will not affect any
                                            rights and obligations incurred prior to the date of termination of the User
                                            Agreement and Account closure.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            2.13 If a User Agreement is terminated, User may be required to either cancel or
                                            complete all open Orders and Funds will be returned to the verified bank account
                                            and/or to an external wallet of User. For compliance reasons and if User makes
                                            use of the Off-Chain Staking Services, this may take longer. At Coiniy.com’s
                                            discretion, the Funds may be required to be exchanged first, e.g. Digital Assets
                                            for Coiniy.com E Tokens.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            2.14 In the event that the costs of closing of the Account exceed the value of a
                                            User’s Funds, User shall reimburse Coiniy.com.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            2.15 User may not use the Account and the Services to perform criminal activities
                                            of any sort, including but not limited to money laundering, terrorist financing,
                                            sanction evasing, fraud or any other criminal or illegal activity. These include,
                                            but are not limited to, the following:
                                        </p>
                                        <ul>
                                            <li>
                                                2.15.1 Activity which would violate, or assist in violation of, any
                                                directive, law, guideline or other regulation related to sanctions programs
                                                administered in the countries where Coiniy.com conducts business, including
                                                but not limited to the EU Sanctions list.
                                            </li>
                                            <li>
                                                2.15.2 Activity which would violate, or assist in violation of, any
                                                directive, law, guideline or other regulation related to terrorist financing
                                                as applicable in the countries where Coiniy.com conducts business.
                                            </li>
                                            <li>
                                                2.15.3 Activity which would violate, or assist in violation of, any
                                                directive, law, guideline or other regulation related to money laundering as
                                                applicable in the countries where Coiniy.com conducts business.
                                            </li>
                                            <li>
                                                2.15.4 Activity which would be related to fraud such as activities which
                                                operate to defraud Coiniy.com (including Users providing any false,
                                                inaccurate, or misleading information to Coiniy.com), User and/or other
                                                persons.{' '}
                                            </li>
                                            <li>
                                                2.15.5 Activity which would be related to drugs such as sale of narcotics,
                                                controlled substances, and any equipment designed for making or using drugs.
                                            </li>
                                            <li>
                                                2.15.6 Activity which would be related to multi-level marketing such as
                                                pyramid schemes and network marketing.
                                            </li>
                                            <li>
                                                2.15.7 Activity which would be related to unfair, predatory or deceptive
                                                practices such as investment opportunities or other services that promise
                                                (too) high rewards and sites that Coiniy.com determine in its sole discretion
                                                to be unfair, deceptive, or predatory towards Users.
                                            </li>
                                            <li>
                                                2.15.8 Activity which would be related to illegal gambling such as
                                                unregulated lotteries, sports forecasting or odds making and comparable
                                                activities.
                                            </li>
                                            <li>
                                                2.15.9 Activity which would be related to high-risk businesses such as any
                                                businesses that Coiniy.com believes to pose elevated financial risk, legal
                                                liability, or violates bank policies.
                                            </li>
                                            <li>
                                                2.15.10 Activity which would be related to (attempts to) to reverse engineer,
                                                modify, translate or disassemble the (software behind the) Coiniy.com
                                                Platform in whole or in part.
                                            </li>
                                        </ul>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            2.16 User may not use the Account and Services to perform any business practices
                                            or activities of any sort, unless agreed otherwise with Coiniy.com.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            2.17 Coiniy.com may, in its sole discretion, determine the existence of any of
                                            the activities as mentioned under article 2.15
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            2.18 Coiniy.com may suspend or block specific or all use of an Account, suspend
                                            or cancel any pending Order, suspend or cancel any deposit or withdrawal, freeze
                                            the Funds related to an Account or suspend the cancellation of an Account for the
                                            duration of an investigation into misuse of the Account or the Services. If the
                                            legitimacy of the User or any Order or use of the Services cannot be determined,
                                            the User may lose all rights to its Funds.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            2.19 Coiniy.com is authorized to notify the relevant authorities and will provide
                                            data to the relevant authorities to the extent required by law and regulation.{' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            2.20 If User is uncertain as to whether or not a specific use of the Services is
                                            allowed, User can send an email to{' '}
                                            <a href='mailto: compliance@coiniy.com'>compliance@coiniy.com</a>.
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            3. Verification and limits
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            3.1 Coiniy.com uses systems and procedures to collect and verify information
                                            about Users in order to protect Users and Coiniy.com from fraudulent activities,
                                            and to keep appropriate records of Users.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            3.2 Both prior to the opening of an Account and on an ongoing basis, User agrees
                                            to provide Coiniy.com with all requested information for the purpose of adherence
                                            with know-your-customer rules, as amended from time to time, and the detection of
                                            money laundering, terrorist financing, sanctions, fraud or any other criminal or
                                            illegal activity.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            3.3 The initial verification process and any additional verification processes
                                            take time and may result in delayed Services which could result in adverse
                                            consequences for User. User is aware and accepts these risks and acknowledges and
                                            agrees that Coiniy.com could not be liable for any delayed Services resulting in
                                            adverse consequences for User.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            3.4 Coiniy.com reserves the right to cancel and/or terminate Accounts that have
                                            not been verified, if not all required information has been received or if this
                                            is required based on any information received.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            3.5 By visiting or registering for the Services, you authorize the collection,
                                            use and disclosure of information in accordance with our Privacy Policy. A copy
                                            of the most recent version of our Privacy Policy can be obtained from our
                                            Website. Coiniy.com will process your personal data in accordance with the
                                            Privacy Policy.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            3.6 User’s access to one or more Services might include limits - including daily,
                                            weekly, monthly, annually and total (i) trading limits, (ii) deposit limits for
                                            Funds and/or (iii) withdrawal limits for Funds - based on the information
                                            provided by User and available information on the User.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            3.7 Coiniy.com may include the different trading limit thresholds as an
                                            indication on its Website. Coiniy.com reserves the right to amend the procedures
                                            and limits from time to time without prior notice.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            3.8 User may submit a request for higher limits. Coiniy.com may require User to
                                            submit additional documentation in this regard and does not guarantee that limits
                                            will be raised.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            3.9 Coiniy.com may require User to provide or verify additional information, or
                                            to wait some amount of time after completion of a trade or a transaction, before
                                            permitting User to use any Services and/or before permitting User to engage in
                                            transactions beyond certain volume limits.
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            4. Funds
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            4.1 User may store Funds on their Account, to be used for buying, selling or
                                            trading Digital Assets on the Coiniy.com Platform. The Digital Assets and the
                                            Coiniy.com E Tokens are displayed as the account balance in the Account of User.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            4.2 As agreed between Coiniy.com and Coiniy, the Coiniy is responsible for
                                            keeping and managing all Funds to which the Users have a direct claim, for the
                                            purpose of safekeeping. Coiniy is a party to this User Agreement exclusively for
                                            this purpose of asset segregation and the custody of the Funds. Coiniy may make
                                            use of the services of banks and third party custody providers for the
                                            safekeeping of the Funds.{' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            4.3 User warrants that all (current and future) Funds in the Account are not the
                                            direct or indirect proceeds of any criminal or fraudulent activity and are kept
                                            only for User and not for any third parties.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            4.4 Coiniy.com reserves the right to investigate the source of any Funds in the
                                            Account and take appropriate measures with a view to mitigate potential integrity
                                            risks. Coiniy.com may return the Funds to the destination of their origin or, if
                                            requested by User and at the sole discretion of Coiniy.com, elsewhere. Coiniy.com
                                            may in its sole discretion determine that Funds must be withdrawn from the
                                            Account as a form of Funds (Euro or Digital Assets) that is similar to a deposit
                                            made to that Account.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            4.5 Coiniy.com may force an exchange of User’s Digital Assets for a corresponding
                                            Euro amount or vice versa:{' '}
                                        </p>
                                        <ul>
                                            <li>4.5.1 in order to fulfill its statutory obligations; </li>
                                            <li>4.5.2 in order to fulfill its obligations under this Agreement; or</li>
                                            <li>
                                                4.5.3 if this is required for a proper provision of the Services, at
                                                Coiniy.com’s sole discretion.
                                            </li>
                                        </ul>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            4.6 In the case of suspected fraud or misuse, Coiniy.com has the right to suspend
                                            or cancel deposits and withdrawals until the legitimacy has been verified.
                                            Coiniy.com may in its sole discretion determine that a transfer needs additional
                                            examination, requires cancellation, or is to be returned.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            4.7 User may only transfer Funds to or from bank accounts and wallet addresses
                                            that are under the sole control and/or that are fully owned by User. Coiniy.com
                                            is not under any obligation towards User to verify what party controls or what
                                            parties control the bank accounts and the wallet addresses that Funds are being
                                            transferred to, including but not limited to a verification of the legitimacy of
                                            the intentions of the receiving party.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            4.8 User must and shall check all deposits or withdrawals, including destination
                                            address, thoroughly before providing any deposit or withdrawal instruction on the
                                            Coiniy.com Platform. Once an instruction is provided, deposits or withdrawals are
                                            final, cannot be cancelled and are irreversible. Any mistakes in submitted
                                            deposits or withdrawals are the sole responsibility of the user.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            4.9 Deposits and withdrawals of Funds may be significantly delayed or unavailable
                                            or the Funds may be lost entirely due to the inherent nature of or errors in the
                                            financial system or the Digital Asset network.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            4.10 Coiniy.com may implement a so-called ‘cooldown period’ after a User deposits
                                            Funds on its Account, for compliance reasons. During the cooldown period, Funds
                                            can not be withdrawn from the Account in the form of Digital Assets or may be
                                            withdrawn at all.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            4.11 If an Account is not used for a certain period of time and Coiniy.com is
                                            unable to contact User, Coiniy.com may report any Funds remaining in the Account
                                            as unclaimed property. Coiniy.com may deduct an administrative fee resulting from
                                            such unclaimed Funds. If the costs of holding the Funds in an Account or the
                                            costs of a transfer of the Funds to another party to hold the Funds on behalf of
                                            User are higher than the estimated value of the Funds, Coiniy.com may close the
                                            Account, whereby User shall lose all rights to the Funds.
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            5. Coiniy E-Token
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            5.1 The Coiniy E-Tokens, which are exclusively offered by Coiniy.com and can only
                                            be used within the Coiniy.com Platform, are to be used by Users for purchasing
                                            and selling Digital Assets on the Coiniy.com Platform.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            5.2 Coiniy E-Tokens may only be used for the Services and may not be stored for
                                            other purposes. User is not entitled to interest or any other compensation for
                                            the stored Coiniy.com E-Tokens. In case there are indications that User uses the
                                            Coiniy.com E-Tokens for other purposes, Coiniy.com may at its discretion i)
                                            contact User and request proper use or withdrawal of the Coiniy.com E-Tokens or
                                            ii) return the Funds to the destination of their origin or elsewhere as agreed
                                            between User and Coiniy.com.{' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            5.3 The Coiniy E-Tokens are automatically issued by Coiniy.com without delay upon
                                            receipt of a User’s payment in Euro or USD. For the avoidance of doubt, a payment
                                            service provider facilitating such Euro payment is facilitating the payments in
                                            relation to the User’s purchase of E-tokens and is not involved in the issuance
                                            of Coiniy E Tokens or the use of Coiniy E-Tokens on the Coiniy.com Platform.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            5.4 Coiniy.com issues Coiniy E-Tokens at par value, due to which the Coiniy
                                            E-Tokens will have the same amount as the Euros received, and therefore a Coiniy
                                            E-Token is issued for every euro received. The Coiniy E-Tokens issued to a User
                                            will be added to the Coiniy E-Token Wallet linked to the Account of User.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            5.5 Coiniy.com holds the received Euro funds for the Users for the purpose of
                                            safekeeping. Upon User’s request, User may redeem its Coiniy E-Tokens for Euros.
                                            In such case the Coiniy E-Tokens will automatically be redeemed from the Account
                                            at par value by Coiniy and the Coiniy.com will transfer the amount in Euro
                                            corresponding to the value of the redeemed Coiniy E-Tokens to User via a bank
                                            transfer to the verified bank account of User.
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            6. Digital Assets
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            6.1 After transferring Digital Assets by the User to the Coiniy.com Digital Asset
                                            Wallet, the Coiniy.com holds the Digital Assets for User for the purpose of
                                            safekeeping. User has a direct claim on the Coiniy.com for providing the Digital
                                            Assets that are held by the Coiniy.com for User for the purpose of safekeeping.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            6.2 Upon first request, User may withdraw Digital Assets to which the User is
                                            entitled. In such case the Coiniy.com will transfer the Digital Assets as
                                            requested by the User to the wallet address of User (provided User is entitled to
                                            such Digital Assets).
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            6.3 Coiniy.com may add or remove Digital Assets from the Coiniy.com Platform and
                                            discontinue Services in relation to specific Digital Assets if this is necessary
                                            to comply with regulatory or technical measures or at its sole discretion without
                                            any reason being required, without prior notice and without being due any
                                            reimbursement to User.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            6.4 User is not entitled to airdrops, staking rewards or any other compensation
                                            that are issued in relation to any Digital Asset, except if Coiniy.com decides
                                            otherwise or as otherwise included in this User Agreement.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            6.5 User is not entitled to participate in any staking functionality (with the
                                            exception of making use of the Off-Chain Staking Services and On-Chain Staking
                                            Services, see below), delegation, voting or other governance functionality with
                                            respect to the Digital Assets in its Account.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            6.6 A deposit of a Digital Asset will only be added to and represented in the
                                            Account of User once multiple blockchain network confirmations of the transfer
                                            have been received. What number of network confirmation suffices is in
                                            Coiniy.com’s sole discretion and may differ per Digital Asset.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            6.7 Only Digital Assets that are included on the Coiniy.com Platform and
                                            communicated as such by Coiniy.com may be deposited. What Digital Assets are
                                            included may change from time to time. Under no circumstances should User attempt
                                            to deposit Digital or receive Digital Asset in any form that Coiniy.com does not
                                            support. This includes side chains or forked protocols of Digital Assets that are
                                            included on the Coiniy.com Platform. Attempting to deposit or receive such
                                            Digital Assets will lead to all such Digital Assets being lost.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            6.8 User shall only deposit Digital Assets to its Coiniy.com Digital Asset Wallet
                                            using a network that is supported by Coiniy.com for that Digital Asset.
                                            Coiniy.com indicates which network it supports on the deposit page of the
                                            relevant Digital Asset on the Coiniy.com Platform. Attempting to deposit a
                                            Digital Asset using a network that is not supported by Coiniy.com will lead to
                                            this Digital Asset being lost.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            6.9 User acknowledges that storing Digital Assets in a Coiniy.com Digital Asset
                                            Wallet involves significant risks, as described in more detail in the Risk
                                            Disclosure Statement. Although Coiniy.com will take reasonable measures to secure
                                            the Coiniy.com Digital Asset Wallets, complete security cannot be guaranteed.
                                            User acknowledges that any use of the Coiniy.com Digital Asset Wallet is at the
                                            sole risk of User.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            6.10 Coiniy.com and the Digital Assets rely on third party software protocols
                                            that govern the operation of the Digital Assets supported by the Services.
                                            Coiniy.com has no influence on the functionality, security, availability, and/or
                                            continuity of these software protocols. The underlying software protocols
                                            relating to any Digital Asset may (suddenly) change, which may materially affect
                                            the value, function, transferability, and/or other aspects of the Digital Asset.
                                            Coiniy.com is not liable for any damage relating to and/or caused by these
                                            software protocols, including, but not limited to (i) damage arising out of the
                                            discontinuance of a software protocol, and/or (ii) damage arising out of an
                                            unsettled deposit or withdrawal caused by an error in a software protocol.
                                            Coiniy.com shall not be liable if Digital Assets are lost due to an error in a
                                            software protocol.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            6.11 For security and efficiency purposes, the Foundation may store Digital Asset
                                            offline and with third parties, including custody providers. This may lead to a
                                            delay in the withdrawal of Digital Asset. Coiniy.com have no influence on the
                                            functionality, security, availability, and/or continuity of these third party
                                            custody provider’s services and shall not be liable for any damage relating to
                                            and/or caused by (i) lack of quality of these third party custody provider’s
                                            services, (ii) their downtime or (iii) any other issue in connection with these
                                            custody providers. User acknowledges that it is aware of the possibility that in
                                            case of default by - or bankruptcy of - a third party custody provider, User may
                                            not be able to retain (all) its Digital Assets.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            6.12 Coiniy.com endeavors to avoid that the Services are only provided in
                                            relation to Digital Assets that qualify as a ‘financial instrument’ or a
                                            ‘financial product’ as included in applicable laws and regulations. Coiniy.com
                                            provides no warranty that any Digital Asset on the Coiniy.com Platform or for
                                            which Coiniy.com offers Services otherwise is not a ‘financial instrument’ or a
                                            ‘financial product’.
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            7. Orders, Transactions and Trading
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            7.1 Users can submit instructions to buy or sell Digital Assets on the Coiniy.com
                                            Platform (placing an Order), against other Digital Assets or Coiniy E-Tokens,
                                            where pairs are offered by Coiniy.com from time to time.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            7.2 The initiation and fulfillment of Orders is subject to rules regarding,
                                            amongst other things, execution, fees, and integrity. These rules are described
                                            in more detail in the Trading Rules.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            7.3 Users may place Market Orders, Limit Orders or Stop Limit Orders. A“Market
                                            Order” is an instruction from the User to execute an Order immediately and for
                                            the best available price at that time. A“Limit Order” is an Order in which the
                                            user specifies a maximum price at which it wants to buy or a minimum price at
                                            which it wants to sell. A“Stop Limit Order” is an instruction from User to place
                                            a Limit Order when a certain trigger price is reached or exceeded.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            7.4 The execution price of a Market Order may differ significantly from the
                                            indicated price. This may be due to a change in price between the time of the
                                            Order being initiated by User and the execution of the Order (i.e. 'slippage').
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            7.5 Market Orders, Limit Orders, and Stop Limit Orders are not guaranteed
                                            executable due to the required availability of sufficient supply or demand.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            7.6 Orders may be executed instantly or after a period of time and will be
                                            considered to have taken place at the execution date and time.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            7.7 Coiniy.com may apply minimum and maximum Order amounts which may vary per
                                            trading pair.{' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            7.8 Coiniy.com is responsible for the correct execution, clearance and settlement
                                            of the purchase and sale of Digital Assets on the Coiniy.com Platform.{' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            7.9 User has sole responsibility for Orders placed, deposits and withdrawals made
                                            and other instructions provided by it. Coiniy.com may provide information on
                                            Digital assets, such as price, range, and volatility. This must not be considered
                                            as investment advice but as information or an opinion.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            7.10 Third parties may provide information about the price of Digital Assets on
                                            the Coiniy.com Platform independently of Coiniy.com. In case of deviation the
                                            indicated price on the Coiniy.comPlatform shall prevail, provided that the latter
                                            can also differ from the actual execution price in case of a Market Order.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            7.11 In exceptional cases, there may be errors in the Coiniy.com Platform, prices
                                            displayed or the way in which Orders are matched. Coiniy.com may at its sole
                                            discretion and retrospectively correct any errors, by amending or reverting
                                            transactions, which may be to the advantage or to the disadvantage of User. User
                                            may not take advantage of any errors on the Coiniy.com Platform.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            7.12 Especially during periods of high volume, illiquidity, fast movement or
                                            volatility, any Digital Assets traded on the Platform may be executed at a
                                            different rate than indicated via the Services at the time of Users Order.{' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            7.13 In the case of suspected fraud or misuse, Coiniy.com reserves the right to
                                            suspend or cancel Orders and transactions until the legitimacy has been verified.{' '}
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            8. On-Chain Staking Services
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            8.1 When User holds Digital Assets in his Coiniy.com Digital Asset Wallet, User
                                            may be given the option to ‘stake’ these Digital Assets in a third party proof of
                                            stake network via staking services provided by Coiniy.com (the“On-Chain Staking
                                            Services”).
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            8.2 In order to use the On-Chain Staking Services, User must opt-in by selecting
                                            the On-Chain Staking Services which will apply for all supported Digital Assets
                                            (the“On Chain Staking Digital Assets”) as held in User’s Coiniy.com Digital Asset
                                            Wallet. Coiniy.com may decide to add or remove Digital Assets from the list of
                                            On-Chain Staking Digital Assets at any time in its sole discretion.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            8.3 The On-Chain Staking Services are always offered in conjunction with the Off
                                            Chain Staking Services. User is aware of the significant risks related to the
                                            On-Chain Staking and Off-Chain Staking Services, as described in more detail in
                                            the Risk Disclosure, and is not required to stake with Coiniy.com. User can
                                            opt-out from the On-Chain Staking Services (and Off-Chain Staking Services) at
                                            any time through the settings page in his Account.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            8.4 By opting-in for the On-Chain Staking Services, Coiniy.com will stake User’s
                                            On Chain Staking Digital Assets on User’s behalf. If Coiniy.com receives a reward
                                            for these staking activities, Coiniy.com will distribute (part of) the reward to
                                            User (the“On Chain Staking Reward”). Most On-Chain Staking Rewards will be
                                            distributed in the same On-Chain Staking Digital Assets although the reward for
                                            some On-Chain Staking Digital Assets might be distributed in another Digital
                                            Asset (e.g. NEO with GAS or VeChain Token with VeChain Thor Energy). The
                                            percentage and timing of such distribution will: (i) be determined by Coiniy.com
                                            in its sole discretion; (ii) be subject to Coiniy.com’s staking fee (if
                                            applicable); (iii) vary per On-Chain Staking Digital Asset; and (iv) be further
                                            detailed in the Account of User. An indication of the potential Staking Reward is
                                            published on the Website although this is only an estimate and might change over
                                            the time.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            8.5 User acknowledges and agrees to have no right to a On-Chain Staking Reward
                                            until it is received by Coiniy.com. On-Chain Staking Rewards will be distributed
                                            to the Account of User promptly after they are received by Coiniy.com. Coiniy.com
                                            employs measures to ensure that the On-Chain Staking Services are accessible 24
                                            hours a day and 7 days a week although Coiniy.com does not guarantee that User
                                            will receive On-Chain Staking Rewards, any specific On-Chain Staking Reward, or
                                            any other staking return over time.
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            9. Off-Chain Staking Services
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            9.1 The Off-Chain Staking Service is a separate service from the On-Chain Staking
                                            Services described in article 8 of this User Agreement. The On-Chain Staking
                                            Services allow Coiniy.com to stake User’s On-Chain Staking Digital Assets on
                                            User’s behalf and, if Coiniy.com receives a reward for these On-Chain Staking
                                            activities, User will receive a On-Chain Staking Reward which is (part of) the
                                            reward as received by Coiniy.com. The off-chain staking services allow User to
                                            instruct the Coiniy.com to contribute Digital Assets to Coiniy.com, and
                                            subsequently to other relevant third parties (the “Lending Partners”), in
                                            exchange for the opportunity to earn rewards (“Off-Chain Staking Services”).
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            9.2 In order to use the Off-Chain Staking Services, User must opt-in by selecting
                                            the Off-Chain Staking Services which will apply for all Digital Assets as held in
                                            User’s Coiniy.com Digital Asset Wallet (“Off-Chain Staking Digital Assets”). The
                                            Off-Chain Staking Services are always offered in conjunction with the On-Chain
                                            Staking Services. User is aware of the significant risks related to Off-Chain
                                            Staking services, as described in more detail in the Risk Disclosure, and User is
                                            not required to use the Off-Chain Staking Services at Coiniy.com. User can
                                            opt-out from the Off-Chain Staking Services (and the On-Chain Staking Services)
                                            at any time through the settings page in its Account.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            9.3 By using the Off-Chain Staking Services User (i) Coiniy the right to assign
                                            (part of) this Agreement and the corresponding duties relevant for the Off-Chain
                                            Staking Digital Assets to Coiniy.com and gives a right of use and its consent to
                                            use the Off Chain Staking Digital Assets by Coiniy.com after the Coiniy has
                                            assigned (part of) this Agreement and its corresponding duties relevant for the
                                            Off-Chain Staking Digital Assets to Coiniy.com (ii) confirms to have read and
                                            understood the relevant terms and conditions entirely (iii) confirms to have had
                                            the opportunity to ask questions and receive answers from Coiniy.com concerning
                                            the terms and conditions of the Off Chain Staking Service prior to participating
                                            and (iv) acknowledges and accepts the significant risks associated with Off-Chain
                                            Staking Services as described in more detail in the Risk Disclosure Statement.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            9.4 User acknowledges and agrees to only contribute Off-Chain Staking Digital
                                            Assets to the Off-Chain Staking Services that are initially deposited into User’s
                                            Account for the purpose of trading Digital Assets on the Coiniy.com Platform and
                                            not for participation in the Off-Chain Staking Services.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            9.5 Off-Chain Staking Digital Assets that User contributes to the Off-Chain
                                            Staking Services (“Off-Chain Staked Digital Assets”) are automatically treated as
                                            received except if indicated otherwise on the Website. Coiniy.com reserves the
                                            right to (i) reject or remove any contribution from the Off-Chain Staking
                                            Services; (ii) establish minimum or maximum contribution amounts; or (iii) return
                                            any Off-Chain Staked Digital Assets, at any time by Coiniy.com and in its sole
                                            discretion.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            9.6 User grants Coiniy.com the right, to the fullest extent that Coiniy.com may
                                            effectively do so under applicable law and without further notice to User, to
                                            utilize the Off-Chain Staking Digital Assets in Coiniy.com’s discretion;
                                            provided, that Coiniy.com will re-assign (part of) this Agreement and its
                                            corresponding duties relevant for the Off-Chain Staking Digital Assets and return
                                            the quantity and type of User’s Off-Chain Staking Digital Assets back to the
                                            Coiniy on behalf the User when User opts-out from the Off-Chain Staking Services
                                            (and the On-chain Staking Services).
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            9.7 If The Coiniy.com acting on behalf of User assigns (part of) this Agreement
                                            and the corresponding duties relevant for the Off-Chain Staking Digital Assets to
                                            Coiniy.com, User remains the beneficial owner of the Off-Chain Staked Digital
                                            Assets and the beneficial owner of any additional Digital Assets resulting from
                                            any applicable (i) network fork that is supported by Coiniy.com and/or (ii)
                                            airdrop or similar distribution of Digital Assets that is supported by
                                            Coiniy.com; provided, that Coiniy.com retains sole discretion and has no
                                            obligation to support any particular forks, airdrops or similar distribution and
                                            such support remains in all instances subject to the User Agreement.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            9.8 User may earn rewards on Off-Chain Staked Digital Assets (“Off-Chain Staking
                                            Rewards”). Off-Chain Staking Rewards are not guaranteed and the rates and timing
                                            of Off-Chain Staking Rewards may vary.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            9.9 Off-Chain Staking Reward rates will be determined based on the number of Off
                                            Chain Staked Digital Assets per type of Off-Chain Staked Digital Asset and the
                                            reward rate per Off-Chain Staked Digital Assets which is determined in
                                            Coiniy.com’s sole discretion and might vary per the Off-Chain Staked Digital
                                            Assets type. An indication of the potential Off-Chain Staking Reward (if any) is
                                            published on the Website although this is only an estimate and might change over
                                            time.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            9.10 Rewards, if any, will be paid by Coiniy.com (i) to User’s Account in the
                                            same Digital Asset as the applicable Off-Chain Staked Digital Assets, and then
                                            (ii) automatically contributed to the Off-Chain Staking Services, whereupon User
                                            will be eligible to earn Off-Chain Staking Rewards.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            9.11 Coiniy.com reserves the right to charge User fees in connection with User
                                            participating in the Off-Chain Staking Services. A schedule of these fees for
                                            using the Off-Chain Staking Services, if any, will be made available on the
                                            Website. Fees are subject to change, at Coiniy.com’s sole discretion, and User is
                                            solely responsible for accessing the fee schedule prior to participating in the
                                            Off-Chain Staking Services.
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            10. Fees and Costs
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            10.1 User shall be due fees to Coiniy.com, which include but may not be limited
                                            to fees for trading, for depositing Digital Assets or euro Funds, for withdrawing
                                            Digital Assets or euro Funds and for making use of the Off-Chain Staking Services
                                            or the On-Chain Staking Services.{' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            10.2 Any fees and the relevant fee amounts shall be published by Coiniy.com on
                                            the designated fee information pages on the Website and/or the Coiniy.com
                                            Platform, as amended from time to time, or shall be made available to User
                                            otherwise. Coiniy.com may make changes hereto in its sole discretion from time to
                                            time. Any changes made available to User shall become effective directly.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            10.3 Coiniy.com may levy any fees by deducting these from User’s Funds in the
                                            Coiniy.com Digital Asset Wallet or Coiniy E-Token Wallet, from an amount of
                                            Digital Assets or Coiniy E-Tokens resulting from a trade, from Digital Assets
                                            received as a result of using the Off-Chain Staking Services or otherwise.
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            11. Security
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            11.1 User is solely responsible for safeguarding access to its Account. This
                                            includes but is not limited to choosing sufficiently complex and difficult to
                                            decipher passwords, safely storing any passwords, preventing third party access
                                            to its email accounts, not keeping, storing or recording their individual
                                            combination of username and password by electronic or other means together with
                                            User’s other documents relating to Coiniy.com, not leaving the device from which
                                            User is logged in to the Platform unattended, safeguarding the API Credentials
                                            and taking all reasonable steps to avoid the loss, theft or misuse of the
                                            electronic devices that User utilizes to access the Platform. Coiniy.com cannot
                                            be held liable for any misuse of User's login credentials or API Credentials by a
                                            third party. Without any information to the contrary, Coiniy.com shall assume
                                            that the person logged in to User's Account using the correct login credentials
                                            or using the API Credentials is User. User accepts responsibility for – and bears
                                            the full risk of – any and all activities that occur under User’s Account and all
                                            transactions entered into under User’s Account.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            11.2 Coiniy.com strongly recommends switching on and using two factor
                                            authentication for added security for your Coiniy.com Account as well as other
                                            accounts affiliated with your Coiniy.com Account, such as your email account.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            11.3 Coiniy.com will never ask User to provide passwords or two factor
                                            authentication information, other than as requested when logging in to the
                                            Account for use of the Coiniy.com Platform or when making changes to log-in
                                            credentials. User may never respond to another request to provide this
                                            authentication data, e.g. by email, even if the request appears to originate from
                                            Coiniy.com.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            11.4 In the event that User knows or should reasonably know that its
                                            authentication data has been stolen or may be misused, User must contact
                                            Coiniy.com immediately.
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            12. Privacy
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            12.1 In providing the Services, Coiniy.com may collect and process personal data.
                                            The main purpose of collecting the data is to provide the services to User. The
                                            Privacy Policy and Cookie Policy are applicable to all use of the Services and
                                            make User aware of the collected data, how it is used and with which parties it
                                            is shared.
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            13. Confidentiality
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            13.1 User may receive information relating to Coiniy.com or the Services that is
                                            not known to the general public. User hereby agrees to only use such confidential
                                            information insofar this is necessary to use the Services and agrees not to
                                            disclose any of this confidential information to any third parties or persons
                                            unless expressly permitted in writing by Coiniy.com to do so. All confidential
                                            information will remain property of Coiniy.com.{' '}
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            14. Intellectual Property Rights
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            14.1 Coiniy.com and its affiliates own all right, title and interest, including
                                            all related Intellectual Property Rights, vesting in and related to the (content
                                            of the) Coiniy.com Platform and the Services offered by Coiniy.com, except as
                                            included in 14.2 below. The same applies to any suggestions, ideas, enhancement
                                            requests, feedback, recommendations or other information provided by User
                                            relating to the Services. These User Terms do not convey any rights of ownership
                                            related to the Intellectual Property Rights owned by Coiniy.com. No right or
                                            license is granted to use Intellectual Property Rights owned by Coiniy.com, other
                                            than strictly necessary for your use of the Coiniy.com Platform and the Services
                                            in accordance with these terms. In case of any such license, this license is not
                                            sublicensable and revocable by Coiniy.com. The name Coiniy and the Coiniy logo
                                            are trademarks of Coiniy.com and its affiliates and no right or license is
                                            granted to use them.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            14.2 The Services may include open source or third party software, in which case
                                            such software is made available to User under the terms of the applicable
                                            licenses.
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            15. Disputes
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            15.1 User can report any complaints, feedback or questions via{' '}
                                            <a href='mailto: support@coiniy.com'>support@coiniy.com</a>.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            15.2 When contacting Coiniy.com, User is required to provide Coiniy.com with its
                                            name and email address and any other information which may be required to
                                            identify User. When User fails to respond to such a request, the complaint will
                                            not be further processed.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            15.3 Both Coiniy.com and User shall cooperate to achieve a solution to the
                                            satisfaction of both parties.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            15.4 This User Agreement and all other contractual relationships between User and
                                            Coiniy.com, as well as questions regarding the existence and formation of such
                                            relationships shall be governed exclusively by the laws of the EU, with the
                                            exception of rules of private international law. This does not exclude that User
                                            may be protected by mandatory provisions of the country where the User has its
                                            usual place of residence.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            15.5 If User is not a consumer, disputes shall be submitted exclusively to the
                                            competent court inside the EU, without prejudice to the right of Coiniy.com to
                                            commence proceedings against User at its place of residence or habitual abode.
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            16. Services
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            16.1 Coiniy.com may, in its sole discretion, for any reason and without liability
                                            to User, i) modify, discontinue or terminate, temporarily or permanently, part or
                                            all of its Services in general and ii) suspend, restrict or terminate User’s
                                            access to any of the Coiniy.com Services or terminate the User Agreement, at any
                                            moment without prior notice.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            16.2 All Services are provided on an “as is” and “as available” basis without
                                            warranty of any kind. Coiniy.com do not guarantee that the Website, Coiniy.com
                                            Platform or Services will be available for any absolute or relative amount of
                                            time. Coiniy.com do not warrant that access will not be interrupted or that there
                                            will be no delays, failures, errors, loss or similar of transmitted information.
                                            This may include, but is not limited to, unavailability of any of the Services
                                            during times of high volume, illiquidity, fast movement or volatility. Coiniy.com
                                            do not give any implied warranties of title, merchantability, fitness for a
                                            particular purpose and/or non-infringement.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            16.3 Any materials on the Coiniy.com Platform are purely for informational
                                            purposes and may change without notice. User is always solely responsible for
                                            assessing the relevance, accuracy, adequacy and reliability of any materials
                                            provided on the Coiniy.com Platform. Coiniy.com is not liable for any loss
                                            arising directly or indirectly as a result of User acting on these materials.
                                            User should always conduct his own, appropriate research before using any of the
                                            Services. Coiniy.com is not liable for any information that might be provided as
                                            a convenience but is not controlled by Coiniy.com, such as links to third party
                                            websites.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            16.4 Coiniy.com shall strive to provide accurate and timely information to User.
                                            However, the content may not always be entirely accurate, complete or current and
                                            it may include inaccuracies or errors.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            16.5 Coiniy.com may at any time transfer, sub-contract or assign any of their
                                            rights and obligations under the User Agreement, Annexes and Related Agreements
                                            or otherwise in relation to the Services to any other third party, without
                                            requiring the approval of User.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            16.6 Information as stored or transferred through the Services may become
                                            irretrievably lost, corrupted or temporarily unavailable due to a variety of
                                            causes, including but not limited to software failures, protocol changes by third
                                            party providers, internet outages, force majeure event or other disasters
                                            including third party DDOS attacks, scheduled or unscheduled maintenance, or
                                            other causes either within or outside Coiniy.com’s control. User is solely
                                            responsible for backing up and maintaining duplicate copies of any information
                                            stored or transferred through the Services.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            16.7 Coiniy.com have no obligation to provide any Services when Coiniy.com may
                                            reasonably believe that this would violate applicable laws and regulations.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            16.8 User is responsible for verifying that information provided by Coiniy.com is
                                            correct and for verifying that information is sent by Coiniy.com when expected.
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            17. Referral Program
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            17.1 Under the Referral Program, eligible Users may share their personal Referral
                                            Link to eligible third parties that may use such Referral Link to become Users.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            17.2 A referred User that opens an Account using the Referral Link shall not be
                                            required to pay any fees over the its first transactions up to a transaction
                                            value of EUR 1,000 and up to seven days following opening an Account. A referring
                                            User receives a commission of 25$ or % over all fees charged by and paid to
                                            Coiniy.com for transactions made by the referred User. User may find a breakdown
                                            of its rewards on the Coiniy.com Platform, currently via this link:
                                            https://app.coiniy.com/affiliate.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            17.3 Coiniy.com may contact the User to arrange specific Referral terms at its
                                            discretion.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            17.4 Under the Referral Program, referring Users may receive a maximum commission
                                            of EUR 500 per month. Should User wish to be eligible for receiving higher
                                            commissions, User and Coiniy.com may agree on specified terms for such purpose.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            17.5 The referring User shall be solely responsible for any taxes due over
                                            received amounts and shall pay such taxes.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            17.6 In sharing their Referral Link, User must act in accordance with the
                                            following requirements and User shall indemnify Coiniy.com for damages incurred
                                            by Coiniy.com in case of a breach of these requirements:
                                        </p>
                                        <ul>
                                            <li>
                                                17.6.1 it must be made clear that the medium, accompanying wording and other
                                                information used for presenting the Referral Link is for the account of the
                                                referring User and not Coiniy.com;
                                            </li>
                                            <li>
                                                17.6.2 it must be made clear that the Services will be provided by Coiniy.com
                                                and not by the referring User;
                                            </li>
                                            <li>
                                                17.6.3 it must be made clear in any public content including the Referral
                                                Link that the referring User receives a commission from use of the Referral
                                                Link;
                                            </li>
                                            <li>
                                                17.6.4 the medium, accompanying wording and other information used for
                                                presenting the Referral Link does or could not:
                                            </li>
                                            <ul>
                                                <li>
                                                    17.6.4.1 include false or misleading information, including on Coiniy.com
                                                    or on the Services;{' '}
                                                </li>
                                                <li>
                                                    17.6.4.2 have a sexual, pornographic, offensive or violent character;{' '}
                                                </li>
                                                <li>
                                                    17.6.4.3 discriminate based on, among others, race, sex, ethnicity or
                                                    creed;
                                                </li>
                                                <li>17.6.4.4 include, promote or offer illegal activities; </li>
                                                <li>17.6.4.5 harm the image, good name and reputation of Coiniy.com; </li>
                                                <li>
                                                    17.6.4.6 violate copyrights, trademarks and/or any other rights of
                                                    Coiniy.com or any third party; and{' '}
                                                </li>
                                                <li>17.6.4.7 include unlawful content or refers to unlawful content;</li>
                                            </ul>
                                            <li>
                                                17.6.5 the Referral Link and related information may not be addressed at
                                                persons:{' '}
                                            </li>
                                            <ul>
                                                <li>
                                                    17.6.5.1 that are not eligible to become Users as per the rules of this
                                                    User Agreement and as per applicable laws and regulations; or
                                                </li>
                                                <li>17.6.5.2 residing in Germany. </li>
                                            </ul>
                                            <li>
                                                17.6.6 all applicable laws and regulations are being complied with, including
                                                but not limited to tax law, general data protection regulation and laws,
                                                regulations applicable to electronic commerce; to the extent applicable);{' '}
                                            </li>
                                            <li>
                                                17.6.7 in case of termination of the Referral Program, the referring User
                                                shall delete any published Referral Links without delay and at the latest
                                                within 48 hours of termination.
                                            </li>
                                        </ul>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            17.7 For the avoidance of doubt, this User Agreement applies in full to persons
                                            that use the Referral Link for the purpose of becoming a User. This includes but
                                            is not limited to the fact that Coiniy.com shall have sole discretion to reject
                                            or refuse any new Users. Any persons that do not become Users shall not generate
                                            any commission for the referring User.{' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            17.8 Coiniy.com may amend rates and other terms regarding the Referral Program at
                                            any time.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            17.9 Users that trade in the highest trading fee tier, of currently minimum EUR
                                            25 million trading volume per thirty days, are excluded from sharing their
                                            Referral Link under the Referral Program. In addition, Coiniy.com may expand or
                                            narrow the Referral Program eligibility requirements and/or exclude any User from
                                            participating in the Referral Program, i) at its full discretion and ii)
                                            including but not limited to for reasons of breaching this User Agreement, fraud,
                                            misuse or manipulation, either by the referring User or by the referred User.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            17.10 The referring User shall be required to refund any commission paid at any
                                            point in time if it is assessed that such commission did not meet the required
                                            criteria or in case of a breach by the referring User of the User Agreement.
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            18. API
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            18.1 Coiniy.com may provide access to Users to its Services and specific data and
                                            information through an ‘Application Programming Interface’ and possibly other
                                            documentation (the “API”). You can find information on how to make use of the API
                                            on our Website: https://coiniy.com/.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            18.2 To use the API, one or more API key(s) with corresponding secrets (the “API
                                            Credentials”) must be created. User is solely responsible for safeguarding the
                                            API Credentials and for any activities by User or third parties that have access
                                            to the Account, whether authorized or not. In the event that User knows, or
                                            should know, that API Credentials have been stolen or misused, User shall contact
                                            Coiniy.com immediately.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            18.3 Coiniy.com may, in its sole discretion, add, discontinue or remove, in whole
                                            or in part, the API at any time and without notice. Coiniy.com cannot guarantee
                                            that future versions of the API will be backwards compatible. User acknowledges
                                            and understands that any of these changes may adversely affect User’s use of the
                                            API and might require User to make substantial changes in order to continue using
                                            the API, and that this is the User’s own responsibility.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            18.4 The scope and concrete form of the API is at Coiniy.com’s sole discretion.
                                            The API may be modified or abandoned at Coiniy.com’s sole discretion.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            18.5 Coiniy.com may monitor the use of the API. User will not block or otherwise
                                            interfere with the monitoring process.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            18.6 Coiniy.com may set limits on the number of calls that User can make using
                                            the API or extend limits for other Users, in its sole discretion, during any
                                            given period and without notice. User shall not attempt to circumvent such
                                            limitations. If User exceeds the limits, Coiniy.com may moderate User activities,
                                            may cease offering access to the API or may immediately suspend or terminate
                                            access to the API in its sole discretion and without notice.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            18.7 User shall not, and shall not encourage or authorize others to:
                                        </p>
                                        <ul>
                                            <li>
                                                18.7.1 use the API in any manner that is not expressly authorized by this
                                                User Agreement;{' '}
                                            </li>
                                            <li>
                                                18.7.2 alter, reproduce, adapt, distribute, display, publish, reverse
                                                engineer, translate, disassemble, decompile or otherwise attempt to create
                                                any source code that is derived from the API;{' '}
                                            </li>
                                            <li>
                                                18.7.3 use or access the API for purposes of monitoring the availability,
                                                performance, or functionality of any of the Services or for any other bench
                                                marking or competitive purposes;
                                            </li>
                                            <li>
                                                18.7.4 collect, cache, aggregate, or store data or content accessed via the
                                                API other than for purposes allowed under this User Agreement;
                                            </li>
                                            <li>
                                                18.7.5 use the API in a manner that exceeds reasonable request volume,
                                                constitutes excessive or abusive usage, or otherwise impacts the stability of
                                                Coiniy.com’s servers or impacts the behavior of other applications using the
                                                API;{' '}
                                            </li>
                                            <li>
                                                18.7.6 attempt to cloak or conceal User’s identity when requesting
                                                authorization to the API;
                                            </li>
                                            <li>
                                                18.7.7 use the API to (i) access or use any information not permitted by this
                                                Agreement, to (ii) circumvent or break any of Coiniy.com’s administrative,
                                                technical or organizational security measures, (iii) disrupt, impair,
                                                overburden or degrade the API or the Services, (iv) change the API or the
                                                Services in any way, or (v) test the vulnerabilities of the Services or API;
                                                and{' '}
                                            </li>
                                            <li>
                                                18.7.8 use any data collected from User’s use of API for advertising
                                                purposes.{' '}
                                            </li>
                                        </ul>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            19. Limitation of Liability
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            19.1 Coiniy.com is dedicated to serving its Users in accordance with its high
                                            standards. However, there are still risks connected to using the Services and to
                                            trading Digital Assets (as described in more detail in the Risk Disclosure
                                            Statement). For this reason, Coiniy.com must limit its liability, as included in
                                            this article.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            19.2 Coiniy.com is not liable for any damage, except if resulting from willful
                                            intent or gross negligence on the side of Coiniy.com. In addition, Coiniy.com is
                                            not liable for any damages:
                                        </p>
                                        <ul>
                                            <li>19.2.1 resulting from actions by User in breach of the User Agreement; </li>
                                            <li>
                                                19.2.2 resulting from actions by User that fall under User’s sole
                                                responsibility, including but not limited to User transferring Funds to a
                                                scam or otherwise fraudulent third party;
                                            </li>
                                            <li>
                                                19.2.3 resulting from a temporary inaccessibility, malfunctioning or
                                                suspension of (a part of) the Services, including, but not limited to (i)
                                                damages resulting from the impossibility to deposit, withdraw or trade Funds,
                                                (ii) damages resulting from the impossibility to log in to or use the
                                                Services, (iii) damages resulting from the cancellation or non-execution of
                                                any Order and (iv) price differences resulting from the delayed processing of
                                                Orders, deposits, or withdrawals;
                                            </li>
                                            <li>
                                                19.2.4 resulting from the execution of an Order initiated by a User,
                                                including, but not limited to, damages resulting from the execution of a
                                                Market Order at a price that differs from the indication price displayed at
                                                the time of the initiation of the Market Order by User;
                                            </li>
                                            <li>
                                                19.2.5 in the form of loss of profits or loss of expected revenue or gains,
                                                including any loss of anticipated trading profits and/or any actual or
                                                hypothetical trading losses, whether direct or indirect, including for
                                                example missed trading opportunities, or actions that User might have taken,
                                                should a certain circumstance have not arisen;
                                            </li>
                                            <li>19.2.6 resulting from force majeure (“enforcement”); </li>
                                            <li>
                                                19.2.7 resulting from a delay or cancellation of any transaction, deposit or
                                                withdrawal of Funds for compliance reasons;
                                            </li>
                                            <li>
                                                19.2.8 resulting from any (i) computer viruses, spyware, Trojan horses or
                                                other malware that may affect User’s computer or other equipment or (ii) SMS,
                                                email services and two factor authentication services which may be vulnerable
                                                to spoofing and phishing attack;
                                            </li>
                                            <li>
                                                19.2.9 as a result of slashing of On-Chain Staking Digital Assets or other
                                                intended functionality or malfunctioning of a proof-of-work staking network
                                                or protocol in relation to On-Chain Staking Digital Assets;
                                            </li>
                                            <li>19.2.10 resulting from the default of one or more Lending Partners; and</li>
                                            <li>
                                                19.2.11 resulting from the actions of a third party for which Coiniy.com is
                                                not responsible.
                                            </li>
                                        </ul>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            19.3 Coiniy.com’s liability to User is limited to:
                                        </p>
                                        <ul>
                                            <li>
                                                19.3.1 all fees and other amounts incurred by Coiniy.com from User under the
                                                User Agreement in the preceding twelve months, exclusive of VAT;
                                            </li>
                                            <li>
                                                19.3.2 in case of damage that could reasonably have been limited if User
                                                would have signaled and reported the damage without undue delay, to the
                                                amount of the damage that would have been incurred if User would have
                                                reported the error to Coiniy.com within 24 hours after User became of should
                                                have become aware of the fact or facts leading to the damage; and{' '}
                                            </li>
                                            <li>
                                                19.3.3 direct losses that were reasonably foreseeable and that follow
                                                directly from a breach of the User Agreement by Coiniy.com.
                                            </li>
                                        </ul>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            19.4 Coiniy.com may in specific cases at its sole discretion decide to reimburse
                                            User for damages or losses while not being under any obligation to do so and
                                            without setting any precedent in such regard. Coiniy.com may also draft policies
                                            for reimbursing damages without being under any obligation to do so and again
                                            without assuming any obligation to reimburse damages going forward. Coiniy.com
                                            may repeal or amend such policies at any time.{' '}
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            20. Indemnification
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            20.1 User agrees to indemnify, defend and hold harmless Coiniy.com and each of
                                            our officers, directors, members, employees, agents and affiliates from and
                                            against any and all claims, actions, audits, demands, damages, losses,
                                            investigations, inquiries, costs or expenses including without limitation
                                            reasonable attorney’s fees, or any other proceeding instituted by a person or
                                            entity that arises out of or relates to (i) any actual or alleged breach of
                                            User’s representations, warranties, or obligations set forth in this User
                                            Agreement (ii) User’s wrongful or improper use of the Services, or (iii) User’s
                                            violation of any rights of any other person or entity.
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            21. Miscellaneous
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            21.1 User is responsible to determine whether, and to what extent, any taxes
                                            apply to any transactions conducted through the Services, and to withhold,
                                            collect, report and remit the correct amount of taxes to the appropriate tax
                                            authorities.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            21.2 Coiniy.com shall provide all communications, notices and disclosures in
                                            connection with the Account electronically. Coiniy.com may provide such
                                            information to User by posting on the Website, emailing to User at its primary
                                            email address listed in the Account, communicating via instant chat or through
                                            mobile push notification.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            21.3 If any article of the User Agreement, Annexes and Related Agreements is
                                            deemed to be unlawful, invalid or unenforceable for any reason, such provision
                                            shall be deemed to be divisible and shall not affect the legal effect of any
                                            other article. 21.4 Coiniy.com’s failure or delay in exercising any right, power
                                            or privilege under this User Agreement shall not operate as a waiver thereof.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            21.5 The User Agreement, Annexes and Related Agreements are governed and
                                            construed in accordance with the laws of the EU. Any claims or disputes will be
                                            settled before the competent court, unless legislation or international
                                            conventions mandatorily prescribe otherwise.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            21.6 Coiniy.com reserves the right to amend, add, or remove parts of the User
                                            Agreement, Annexes and Related Agreements at any time and in its sole discretion.
                                            User will be notified of any changes and although Coiniy.com will endeavor to
                                            provide User with advance notice, Coiniy.com may indicate that the revised User
                                            Agreement, Annexes and Related Agreements shall be effective immediately. If User
                                            does not agree with any such modification, User must close its Coiniy.com Account
                                            and cease using the Services. If User continues to use the Services after a
                                            modification, User is deemed to have accepted and agreed to the amendments. It is
                                            User's responsibility to review the amended User Agreement, the Annexes and
                                            Related Agreements.
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            22. Key Definitions
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            In the User Agreement, Annexes and the Related Agreements, capitalized terms not
                                            otherwise defined in this User Agreement, Annexes and the Related Agreements will
                                            have the following meaning:
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.1 “Account” means an account with Coiniy.com Digital Asset Wallet and Coiniy E
                                            Token Wallet, which gives access to the Services.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.2 “API” means ‘Application Programming Interface’ and any other documentation
                                            through which Coiniy.com may provide access to Users to its Services and specific
                                            data and information
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.3 “API credentials” means one or more API key(s) with corresponding secrets
                                            required to be created to make use of the API.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.4 “Coiniy.com” means Coiniy, a limited liability company incorporated under
                                            the EU law, with its registered office ----.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.5 “Coiniy.com Digital Asset Wallet” means the wallet feature offered by
                                            Coiniy.com to User for the storage and retrieval of a limited number of Digital
                                            Asset as offered within the Coiniy.com Platform.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.6 “Coiniy E-Token” means electronic stored monetary value representing a claim
                                            on Coiniy.com as the issuer of the Coiniy E-Token, which is issued upon receipt
                                            of funds, by Coiniy.com , for the purpose of making payment transactions with
                                            respect to purchases of Digital Asset as offered exclusively within the
                                            Coiniy.com Platform and which shall in no event be seen as any form of deposit
                                            that is held in, for example, a payment or savings account.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.7 “Coiniy E-Token Wallet” means the wallet feature offered by Coiniy.com to
                                            User for the storage and retrieval of Coiniy E-Tokens.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.8 “Coiniy.com Platform” the online platform made available by Coiniy,
                                            accessible via the Website, a mobile application, a widget, through a third party
                                            that is connecting the User to the Coiniy.com Platform by means of an application
                                            programming interface (API) or otherwise, on which Digital Assets can be traded
                                            against other Digital Assets or E-Tokens and on which Coiniy.com executes, clears
                                            and settles such trades.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.9 “Digital Asset” means a digital representation of value, not issued by a
                                            central bank or a public authority, that can be stored or transferred using
                                            blockchain technology.{' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.10 “Off-Chain Staking Digital Assets” means all Digital Assets that are
                                            supported by the Off-Chain Staking Services.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.11 “Off-chain Staked Digital Assets” means all Off-Chain Staking Digital
                                            Assets that User contributes to the Off-Chain Staking Services.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.12 “Off-Chain Staking Rewards” mean interest paid by Coiniy.com to a User
                                            making use of the Off-Chain Staking Services over the Off-Chain Staking Digital
                                            Assets.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.13 “Off-Chain Staking Services” means the Service offered by Coiniy.com
                                            whereby Digital Assets are loaned to Coiniy.com and Off-Chain Staking Rewards are
                                            being provided in return.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.14 “Funds” means the funds as may be deposited by User on the Coiniy.com
                                            Digital Asset Wallet and/or Coiniy.com E-Wallet as linked to the Account, which
                                            may consist of Digital Assets and fiat currency.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.15 “Guarantee Program” means the Coiniy.com Account guarantee program, as
                                            further described as Annex 1 to this User Agreement.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.16 “Incident” means a third party without permission i) gaining access to
                                            User’s Account and ii) withdrawing Funds from such Account.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.17 “Intellectual Property Rights” means any patent, copyright, trademark,
                                            trade name, service mark, service name, brand mark, brand name, logo, corporate
                                            name, internet domain name or industrial design, any registrations thereof and
                                            pending applications therefore (to the extent applicable), and any other
                                            intellectual property right (including, without limitation, any know-how, trade
                                            secret, software (including the API), database or data right).
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.18 “Lending Partners” mean third parties that Coiniy.com will loan the
                                            Off-Chain Staking Digital Assets to, from which Coiniy.com may receive rewards or
                                            other amounts.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.19 “Order” means an instruction to buy or sell Digital Asset on the Coiniy.com
                                            Platform.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.20 “Referral Link” means a link that is personal to an existing User,
                                            referring a third party to opening an Account and becoming a User, generating
                                            advantages for both the referring User and the referred User, in line with the
                                            Referral Program.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.21 “Referral Program” means the program under which an existing User may refer
                                            third persons to become Users, as further described in this User Agreement.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.22 “Reimbursement” means a reimbursement by Coiniy.com under the Guarantee
                                            Program equal to the value of the Funds withdrawn as a result of an Incident, in
                                            the form of a euro amount equal to the value of the Funds withdrawn, at the time
                                            of the withdrawal.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.23 “Services” means all services as offered by Coiniy.com and affiliated
                                            entities, by use of the Website, mobile application, through a third party that
                                            is connecting the User to the Coiniy.com Platform by means of an application
                                            programming interface (API) or otherwise.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.24 “On-Chain Staking Digital Assets” are all Digital Assets that are supported
                                            by the On-Chain Staking Services.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.25 “On-Chain Staking Rewards” mean rewards paid by Coiniy.com to a User making
                                            use of the On-Chain Staking Services over the On-Chain Staking Digital Assets.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.26 “On-Chain Staking Services” means the Service offered by Coiniy.com,
                                            whereby Coiniy.com will stake the On-Chain Staking Digital Assets as a validator
                                            or delegator on User’s behalf in a third party proof-of-stake blockchain protocol
                                            or network, while the On-Chain Staking Digital Assets will remain with the
                                            Coiniy.com.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.27 “User” means an individual or a legal entity that has successfully created
                                            an Account and makes use of the Services.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            22.28 “Website” means <a href='http://coiniy.com'>http://coiniy.com</a> and all
                                            its related subdomains.
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            Annex 1: The Coiniy Account Guarantee Program
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            1. General
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            1.1 Coiniy.com endeavours to provide its Users the best possible experience on
                                            the Platform. In order to accommodate this, Coiniy.com runs a pilot program
                                            called the Coiniy.com Accounts Guarantee Program (the “Guarantee Program”). In
                                            case of contradictions between this Annex and the body of the User Agreement,
                                            this Annex prevails.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            1.2 Under the Guarantee Program, Coiniy.com reimburses damages to eligible Users
                                            equal to the value of the Funds withdrawn (the “Reimbursement”) as a result of a
                                            third party without permission i) gaining access to User’s Account and ii)
                                            withdrawing Funds from such Account (the “Incident”). For the avoidance of doubt,
                                            if User transfers Funds to a third party, for example as a result of a scam or
                                            providing a wrong wallet address, this is not covered by the Guarantee Program.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            1.3 Regardless of the type of Funds as were in the Account, the Reimbursement is
                                            always in the form of a euro amount equal to the value of the Funds withdrawn, at
                                            the time of the withdrawal.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            1.4 The Guarantee Program is a pilot program and as such must be expected to be
                                            subject to changes. Coiniy.com may at any time amend or cancel, in part or in
                                            whole, the Guarantee Program and any of its contents without notifying the User,
                                            at Coiniy.com’s sole discretion.{' '}
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            1.5 User is not required to sign up for the Guarantee Program separately.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            1.6 A maximum of one Incident per User is covered by the Guarantee Program.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            1.7 The Reimbursement is up to a maximum amount of EUR 100.000 (one hundred
                                            thousand euro) per Incident.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            1.8 Any amounts payable by Coiniy.com to a User under the Guarantee Program will
                                            be reduced (including retroactively) by any amounts such User is able to recover
                                            or has already recovered from a source other than the Guarantee Program,
                                            including without limitation, any amounts received under an insurance policy,
                                            warranty, guarantee or indemnity.
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            1.9 The Reimbursement is due, only after Coiniy.com has definitively and beyond
                                            any doubt determined that the evidence for the Incident having taken place is
                                            sufficient and that the User is eligible for the Guarantee Program.
                                        </p>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            2. Eligibility
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            2.1 To be eligible for the Reimbursement, User must:
                                        </p>
                                        <ul>
                                            <li>
                                                2.1.1 have successfully passed the verification process as included in the
                                                User Agreement and be able to trade, deposit and withdraw;{' '}
                                            </li>
                                            <li>
                                                2.1.2 have an Account for at least 30 days following the moment of passing
                                                the verification process as included above, at the moment of the Incident;
                                            </li>
                                            <li>
                                                2.1.3 fully cooperate with any investigation Coiniy.com might conduct
                                                regarding the Incident;
                                            </li>
                                            <li>
                                                2.1.4 have reported the Incident to the relevant law enforcement authorities
                                                and have shared all related documentation as evidence thereof with
                                                Coiniy.com; and{' '}
                                            </li>
                                            <li>
                                                2.1.5 file its request for Reimbursement with Coiniy.com within Fifteen (15)
                                                days of the date on which the Incident occurred.{' '}
                                            </li>
                                        </ul>
                                        <p className='font-text text-dark font-w500 wow fadeInUp' data-wow-delay='0.4s'>
                                            3. Exclusion
                                        </p>
                                        <p className='wow fadeInUp fs-1' data-wow-delay='0.6s'>
                                            3.1 User will not be entitled to any Reimbursement if:
                                        </p>
                                        <ul>
                                            <li>
                                                3.1.1 the Incident is the result of gross negligence or irresponsible
                                                behavior by User, including but not limited to maintaining insufficient
                                                Account security, as included in the User Agreement, which includes inter
                                                alia:{' '}
                                            </li>
                                            <ul>
                                                <li>
                                                    3.1.1.1 User voluntarily sharing with third parties it’s API Credentials,
                                                    it’s login credentials and/or its two factor authentication codes; and
                                                </li>
                                                <li>
                                                    3.1.1.2 User voluntarily granting third parties access to its Account;
                                                </li>
                                            </ul>
                                            <li>
                                                3.1.2 funds withdrawn during the Incident are transferred to a wallet address
                                                which was created by User prior to the Incident; or
                                            </li>
                                            <li>
                                                3.1.3 funds withdrawn during the Incident are extracted by accessing the
                                                Account using an API key generated by User prior to the Incident; or
                                            </li>
                                            <li>
                                                3.1.4 User knowingly partakes or has knowingly partaken in fraudulent
                                                activities in relation to the Incident; or
                                            </li>
                                            <li>
                                                3.1.5 User knowingly partakes or has knowingly partaken in fraudulent
                                                activities in relation to its Account.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default TermsAndConditions
