const renderIcon = (shortName) => {
    return <img src={require(`../assets/images/coin/${shortName}.png`)} alt={shortName} className='img-fluid avatar mx-1' />
}

export const cryptocurrenciesData = [
    {
        value: 'BTC',
        label: 'Bitcoin',
        icon: renderIcon('BTC'),
        default_amount: '0.0006'
    },
    {
        value: 'ETH',
        label: 'Ethereum',
        icon: renderIcon('ETH'),
        default_amount: '0.0071'
    },
    {
        value: 'USDC',
        label: 'USD Coin',
        icon: renderIcon('USDC'),
        default_amount: '1'
    },
    {
        value: 'ADA',
        label: 'Cardano',
        icon: renderIcon('ADA'),
        default_amount: '1'
    },
    {
        value: 'DOGE',
        label: 'Dogecoin',
        icon: renderIcon('DOGE'),
        default_amount: '1'
    },
    {
        value: 'MATIC',
        label: 'Polygon',
        icon: renderIcon('MATIC'),
        default_amount: '1'
    },
    {
        value: 'SOL',
        label: 'Solana',
        icon: renderIcon('SOL'),
        default_amount: '0.01'
    },
    {
        value: 'TRX',
        label: 'TRON',
        icon: renderIcon('TRX'),
        default_amount: '1'
    },
    {
        value: 'LTC',
        label: 'Litecoin',
        icon: renderIcon('LTC'),
        default_amount: '0.036'
    },
    {
        value: 'DOT',
        label: 'Polkadot',
        icon: renderIcon('DOT'),
        default_amount: '0.1'
    },
    {
        value: 'SHIB',
        label: 'Shiba Inu',
        icon: renderIcon('SHIB'),
        default_amount: '1000'
    },
    {
        value: 'DAI',
        label: 'Dai',
        icon: renderIcon('DAI'),
        default_amount: '1'
    },
    {
        value: 'AVAX',
        label: 'Avalanche',
        icon: renderIcon('AVAX'),
        default_amount: '0.1'
    },
    {
        value: 'WBTC',
        label: 'Wrapped Bitcoin',
        icon: renderIcon('WBTC'),
        default_amount: '0.0006'
    },
    {
        value: 'LINK',
        label: 'Chainlink',
        icon: renderIcon('LINK'),
        default_amount: '0.1'
    },
    {
        value: 'ATOM',
        label: 'Cosmos',
        icon: renderIcon('ATOM'),
        default_amount: '0.1'
    },
    {
        value: 'UNI',
        label: 'Uniswap',
        icon: renderIcon('UNI'),
        default_amount: '0.1'
    }
]
