import React, { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'

import { SVGICON } from '../constants/theme'
import useWebSocket from 'react-use-websocket'

import { cryptocurrenciesData } from '../helpers/cryptocurrencies'

const HomeCurrencies = () => {
    const [prices, setPrices] = useState([])

    const { sendMessage, lastMessage } = useWebSocket('wss://api.coiniy.com:2054/')
    const fetchPrices = useCallback(() => sendMessage(JSON.stringify({ channel: 'pricesV1' })), [])

    useEffect(() => {
        fetchPrices()
    }, [])

    useEffect(() => {
        if (lastMessage) {
            const data = JSON.parse(lastMessage.data)
            setPrices(data)
        }
    }, [lastMessage])

    const getFullName = (shortName) => {
        let fullName = ''

        const cryptocurrency = cryptocurrenciesData.find((crypto) => crypto.value === shortName)
        if (cryptocurrency) {
            fullName = cryptocurrency.label
        } else {
            fullName = 'Unknown'
        }

        return fullName
    }

    const cryptoData = Object.entries(prices).map(([symbol, { price }]) => {
        const [baseCurrency, quotedCurrency] = symbol.split('-');

        if (quotedCurrency !== 'USD') {
            return null;
        }

        const fullName = getFullName(baseCurrency)

        return {
            title: fullName,
            subtitle: baseCurrency,
            price: price.toFixed(2)
        }
    }).filter(a => a);

    const filteredCryptocurrenciesData = cryptoData.filter(
        (crypto) => crypto.subtitle === 'BTC' || crypto.subtitle === 'ETH' || crypto.subtitle === 'LTC'
    )

    return (
        <>
            {filteredCryptocurrenciesData.map((data, index) => (
                <div className='col-lg-4 m-b30' key={index}>
                    <div className='crypto-box wow fadeInUp' data-wow-delay='1.0s'>
                        <div className='crypto-media'>
                            <img src={require(`../assets/images/coin/${data.subtitle}.png`)} alt={data.subtitle} />
                        </div>
                        <div className='crypto-info'>
                            <h5>
                                {data.title} <span>{data.subtitle}</span>
                            </h5>
                            <div className='price'>${data.price} </div>
                            <Link
                                to={'https://app.coiniy.com/register'}
                                className='btn btn-square btn-primary '
                                target='blank'>
                                {SVGICON.Arrow}
                            </Link>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default HomeCurrencies
