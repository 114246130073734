import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'

import RiskBanner from './RiskBanner'

import logo from '../assets/images/logo/coiniy_logo.png'
import logoWhite from '../assets/images/logo/coiniy_logo_orange.png'

const Header = (props) => {
    const [headerFix, setheaderFix] = useState(false)

    const NavMenuToggle = () => {
        setTimeout(() => {
            let toggleBtn = document.getElementById('navbutton')
            let sidebarOpen = document.getElementById('navbarNavDropdown')
            toggleBtn.classList.toggle('open')
            sidebarOpen.classList.toggle('show')
        }, 200)
    }

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setheaderFix(window.scrollY > 50)
        })
    }, [])

    return (
        <>
            <RiskBanner />
            <header className={`site-header mo-left header is-risk-banner ${props.style}`}>
                <div className={`sticky-header main-bar-wraper navbar-expand-lg ${headerFix ? 'is-fixed' : ''}`}>
                    <div className='main-bar clearfix '>
                        <div className='container clearfix'>
                            <div className='logo-header'>
                                <Link to={'/'} className='logo-light'>
                                    <img src={logoWhite} alt='Coiniy logo' />
                                </Link>
                                <Link to={'/'} className='logo-dark'>
                                    <img src={logo} alt='Coiniy logo' />
                                </Link>
                            </div>
                            <button
                                className='navbar-toggler collapsed navicon justify-content-end'
                                type='button'
                                id='navbutton'
                                onClick={() => NavMenuToggle()}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                            <div className='extra-nav'>
                                <div className='extra-cell'>
                                    <Link to='https://app.coiniy.com/login' className='btn btn-outline-primary text-primary'>
                                        Login
                                    </Link>
                                    <Link to='https://app.coiniy.com/register' className='btn btn-primary  btn-shadow'>
                                        Register
                                    </Link>
                                </div>
                            </div>
                            <div className='header-nav navbar-collapse collapse justify-content-end' id='navbarNavDropdown'>
                                <div className='logo-header logo-dark fixed-width-mobile'>
                                    <Link to={'#'}>
                                        <img src={logo} alt='Coiniy logo' />
                                    </Link>
                                </div>
                                <ul className='nav navbar-nav navbar navbar-left sub-list-mobile'>
                                    <li>
                                        <NavLink to={'https://app.coiniy.com/login'}>Login</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'https://app.coiniy.com/register'}>Register</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}
export default Header
