import React, { Fragment } from 'react'
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom'

import AnimatedCursor from './elements/AnimatedCursor'
import ScrollToTop from './components/ScrollToTop'

import Home from './pages/Home'

import Footer from './components/Footer'
import Header from './components/Header'

import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsAndConditions from './pages/TermsAndConditions'
import RiskDisclosure from './pages/RiskDisclosure'
import BonusPolicy from './pages/BonusPolicy'

import './assets/css/style.css'
import {ZohoChat} from "zoho-chat";

let basename = '/'

function App() {
    return (
        <Fragment>
            <BrowserRouter basename={basename}>
                <Routes>
                    <Route element={<Layouts />}>
                        <Route path='/' element={<Home />} />
                        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                        <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
                        <Route path='/risk-disclosure' element={<RiskDisclosure />} />
                        <Route path='/bonus-policy' element={<BonusPolicy />} />
                    </Route>
                </Routes>
                <AnimatedCursor />
                <ScrollToTop />
                <ZohoChat
                    url={"https://salesiq.zoho.com/widget"}
                    widgetCode={"1586562194e1677410a92cb21375b2a70046bee9c4dfc558b4995dff4622d1c2"}
                    position={"bottomright"}
                />
            </BrowserRouter>
        </Fragment>
    )
}

function Layouts() {
    return (
        <>
            <div className='page-wraper'>
                <Header style='header-transparent' />
                <Outlet />
                <Footer />
            </div>
        </>
    )
}

export default App
