import React from 'react'
import { Link } from 'react-router-dom'

import twitter from '../assets/images/social/twitter.svg'
import facebook from '../assets/images/social/facebook.svg'
import instagram from '../assets/images/social/instagram.svg'
import appStore from '../assets/images/social/app-store.png'
import googleStore from '../assets/images/social/google-store-new.png'

import logoWhite from '../assets/images/logo/coiniy_logo_orange.png'

const Footer = () => {
    const currentYear = new Date().getFullYear()

    return (
        <footer className='site-footer'>
            <div className='footer-top'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-12 col-sm-12'>
                            <div className='widget widget_about wow fadeInUp' data-wow-delay='0.4s'>
                                <div>
                                    <Link to={'/'} className='logo-light'>
                                        <img src={logoWhite} alt='Coiniy logo' className='footer-app-logo' />
                                    </Link>
                                </div>
                                <div>
                                    <div className='footer-inner'>
                                        <p className='copyright-text wow fadeInUp footer-copyright' data-wow-delay='1.6s'>
                                            Copyright {currentYear} - <a href='/'>Coiniy</a>
                                        </p>
                                        <ul className='footer-list footer-social'>
                                            <li>
                                                <a target='_blank' href='https://twitter.com/' rel='noreferrer'>
                                                    <img src={twitter} alt='Twitter' />
                                                </a>
                                            </li>
                                            <li>
                                                <a target='_blank' href='https://www.facebook.com/' rel='noreferrer'>
                                                    <img src={facebook} alt='Facebook' />
                                                </a>
                                            </li>
                                            <li>
                                                <a target='_blank' href='https://www.instagram.com/' rel='noreferrer'>
                                                    <img src={instagram} alt='Instagram' />
                                                </a>
                                            </li>
                                        </ul>
                                        <div>
                                            <a
                                                href='https://appstore.com/'
                                                rel='noreferrer'
                                                className='me-2 store-link'
                                                target='_blank'>
                                                <img src={appStore} alt='Apple Store' />
                                            </a>{' '}
                                            <a
                                                href='https://playstore.com/'
                                                rel='noreferrer'
                                                className='store-link'
                                                target='_blank'>
                                                <img src={googleStore} alt='Google Store' />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-3 col-6'>
                            <div className='widget widget_links wow fadeInUp' data-wow-delay='0.6s'>
                                <h6 className='footer-title'>Security</h6>
                                <ul className='footer-list'>
                                    <li>
                                        <Link to={'/privacy-policy'}>Privacy Policy</Link>
                                        <Link to={'/terms-and-conditions'}>Terms and Conditions</Link>
                                        <Link to={'/risk-disclosure'}>Risk Disclosure</Link>
                                        <Link to={'/bonus-policy'}>Bonus Policy</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-3 col-6'>
                            <div className='widget widget_links wow fadeInUp' data-wow-delay='1.4s'>
                                <h6 className='footer-title'>Cryptocurrency</h6>
                                <ul className='footer-list'>
                                    <li>
                                        <Link to={'https://app.coiniy.com/register'}>Buy & Sell Crypto</Link>
                                        <Link to={'https://app.coiniy.com/register'}>Buy Bitcoin</Link>
                                        <Link to={'https://app.coiniy.com/register'}>Buy Ethereum</Link>
                                        <Link to={'https://app.coiniy.com/register'}>Buy Litecoin</Link>
                                        <Link to={'https://app.coiniy.com/register'}>Buy Dogecoin</Link>
                                        <Link to={'https://app.coiniy.com/register'}>Buy Shiba</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-3 col-6'>
                            <div className='widget widget_links wow fadeInUp'>
                                <h6 className='footer-title'>Account</h6>
                                <ul className='footer-list'>
                                    <li>
                                        <Link to={'https://app.coiniy.com/login'}>Login</Link>
                                    </li>
                                    <li>
                                        <Link to={'https://app.coiniy.com/register'}>Register</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-bottom'>
                <div className='container'>
                    <div className='footer-inner footer-wrapper'>
                        <p className='copyright-text wow fadeInUp' data-wow-delay='1.6s'>
                            Risk disclosure: Trading cryptocurrencies is highly speculative, carries a level of risk and may
                            not be suitable for all investors. We are required to tell potential investors that Due to the
                            volatile nature of Digital Assets prices, User can incur a substantial and even a full loss of
                            Funds by buying and trading Digital Assets. The user must carefully consider whether trading
                            Digital Assets is suitable for the User’s risk tolerance and financial position. User should not
                            use Funds for the Services that User is not prepared to lose entirely. You may need to seek 3rd
                            party financial advice before engaging in cryptocurrency trading. Coiniy.com is not intended for
                            use by any person in any country where such use would be contrary to local law or regulation. It
                            is the responsibility of visitors to Coiniy.com to ascertain the terms of and comply with any
                            local law or regulation to which they or the trades they undertake are subject.{' '}
                            <a href='/risk-disclosure' target='_blank'>
                                Learn more.
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
